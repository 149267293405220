.mandatory-infromation-product-modal {
    &__message {
        width: 600px;
        max-width: 100%;
        &--creation {
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .profile-edit-modal__form__group {
        margin-bottom: 16px;
    }
    .form-label {
        margin-bottom: 8px;
        display: block;
    }
    &__list {
        padding: 0 0 0 24px;
        margin-bottom: 48px;
        > li {
            margin-bottom: 4px;
            @include get-font($f16, $fw-r, $black, $lh);
        }
    }

    &__sub-title {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-bottom: 24px;
    }
    &__personal {
        border-bottom: 1px solid $grayBackground;
        &__title {
            margin-bottom: 12px;
        }
    }
    .show-interest-product-modal__choose-address {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .profile-view__details__block {
        padding-top: 16px !important;
        border-color: $grayBackground;
    }
    .profile-view__empty__title, .profile-view__details__block__specialisation__title {
        text-transform: uppercase;
        letter-spacing: 0.6px;
        @include get-font($f12, $fw-b, $blue, $lh);
    }
    .profile-view__details__block__professional {
        padding: 12px 0;
        border-bottom: 1px solid $grayBackground;
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        box-shadow: 0 -1px 4px 0 $lightGray;
        width: 100%;
        margin-left: -24px;
        padding: 18px 24px;
        margin-bottom: -24px;
        @media only screen and (max-width: $onlyMobile) {
            padding-bottom: 48px;
        }
        &__name {
            background: $blue;
            padding: 6px 12px;
            @include get-font($f14, $fw-b, $white, $lh);
            text-decoration: none;
            border-radius: 7px;
        }
        &__cancel {
            @include get-font($f14, $fw-r, $removeRed, $lh);
            border:0;
            background: $white;
            cursor: pointer;
        }
        &__proceed {
            @extend %appearance-none;
            height: 32px;
            border: 0;
            background: $black;
            @include get-font($f14, $fw-sb, $white, $lh);
            padding: 0 12px;
            box-sizing: border-box;
            border-radius: 7px;
            cursor: pointer;
            line-height: 32px;
            @include transitionEase(#{background});
            &:focus,
            &:hover,
            &:active {
              background: $black;
            }
            &--disabled {
                background-color: $lightGrey;
                color: $copiedBackground;
                pointer-events: none;
              }
        }
    }
}