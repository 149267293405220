.survey {
  &__playable {
    list-style: none;
    margin: 0;
    padding: 0;
    &__multipleIndication {
      background: #ffffff;
      padding: 16px 0px 0px 0px;
      align-items: center;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      @include get-font($f14, $fw-m, $black);
      border-top: 1px solid rgba(23, 23, 23, 0.05);
      margin-top: 12px;
      &__image {
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }
    }
    &__item {
      background: $white;
      @include form-input-border-shadow();
      margin-top: 24px;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 24px 16px;
      border: 0.5px solid transparent;
      @include transitionEase();
      &--mandatory {
        border-color: $removeRed;
      }
      &__no-response {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-top: 8px;
      }
      &--dragging {
        box-shadow: 0 6px 10px 3px $superLightBlack;
        border: 0.5px solid $superLightBlack;
      }
      &__draggable {
        margin: auto;
      }
      &--addable {
        position: absolute;
        width: 100%;
        min-height: 20px;
        &:hover {
          position: relative;
          margin-top: -24px;
          padding-top: 24px;
          .survey__form__add-question--inline {
            display: block;
          }
        }
      }
    }
    &__title {
      @include get-font($f16, $fw-m, $black, $lh);
      word-break: break-word;
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      > sup {
        color: $red;
      }
    }
    &__description {
      margin-top: 8px;
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      word-break: break-word;
    }
    &__image {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
      &__container {
        width: 100%;
        margin-top: 16px;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid $superLightBlack;
      }
    }
    &__video{
      margin-top: 16px;
      & *{
        border-radius: 10px;
      }
    }
    &__input,
    &__textarea {
      width: 100%;
      @include form-input-border-shadow();
      @include get-font($f16, $fw-r, $black, $lh);
      background: $white;
      border-radius: 10px;
      padding: 12px 16px;
      box-sizing: border-box;
      outline: 0;
      &:focus,
      &:disabled {
        border: 1px solid $black;
        box-shadow: none;
      }
    }
    &__textarea {
      min-height: 120px;
      margin-top: 24px;
      resize: none !important;
      &--min {
        min-height: unset;
      }
    }
    &__text-answer {
      @include get-font($f14, $fw-r, $black, $lh);
      &--margin {
        margin-top: 20px;
      }
    }
    &__select {
      &__text {
        margin-top: 24px;
        &__item {
          display: flex;
          align-items: flex-start;
          margin-top: 20px;
          &:first-child {
            margin-top: 0;
          }
        }
        &__image {
          margin-right: 8px;
        }
        &__title {
          @include get-font($f14, $fw-r, $black, $lh);
        }
        .survey__playable__input {
          margin-top: 16px;
        }
      }
      &__image {
        margin-top: 24px;
        .survey__playable__input {
          margin-top: 16px;
        }
        &__container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &__box {
          border: 1px solid $superLightBlack;
          border-radius: 10px;
          width: 350px;
          position: relative;
          overflow: hidden;
          &:nth-child(n + 3) {
            margin-top: 16px;
          }
        }
        &__image {
          width: 350px;
          height: 257px;
          object-fit: contain;
          background-color: $grayBackground;
        }
        &__check {
          &__container {
            position: absolute;
            top: 0;
            left: 0;
            padding: 6px;
            background-color: $white;
            &--right {
              left: unset;
              right: 0;
              cursor: pointer;
              padding: 10px;
            }
          }
        }
        &__title {
          padding: 12px;
          @include get-font($f14, $fw-r, $black, $lh);
        }
      }
    }
    &__preview-text{
      text-align: center;
      @include get-font($f14, $fw-b, $orange, $lh);
      margin-top: 16px;
    }
    &__action {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid $grayBackground;
      &__btn {
        @extend %appearance-none;
        @include transitionEase(#{background-color});
        background-color: $green;
        padding: 0;
        border: 0;
        border-radius: 10px;
        @include get-font($f16, $fw-sb, $white, $lh);
        text-align: center;
        width: 100%;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        &:hover {
          background-color: $darkGreen;
        }
      }
    }
    &__footer {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid $grayBackground;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__nextBtn {
        background-color: $green;
        padding: 0;
        border: 0;
        border-radius: 10px;
        @include get-font($f16, $fw-sb, $white, $lh);
        text-align: center;
        width: 15%;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: $darkGreen;
        }
        &__img {
          margin-left: 8px;
        }
        &.non-section {
          width: 100%;
        }
      }
      &__prevBtn {
        width: 40px;
        height: 40px;
        border: none;
        background: #d1d1d1;
        border-radius: 8px;
        padding: 6px;
        &__img {
          padding-left: 8px !important;
        }
        &--disabled {
          background: #e1e1e1;
        }
      }
    }
    &__media {
      &__meta {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &__icon {
          width: 20px;
          height: auto;
          margin-right: 12px;
        }
        &__title {
          @include get-font($f14, $fw-m, $black, $lh);
          margin-right: 8px;
        }
        &__subtitle {
          @include get-font($f12, $fw-r, $lightBlack, $lh);
        }
      }
      margin-top: 24px;
      &__image {
        &__images {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
        > label {
          cursor: pointer;
        }
        height: 120px;
        width: 120px;
        object-fit: contain;
        &__container {
          position: relative;
          height: 120px;
          width: 120px;
          border-radius: 10px;
          background: $grayBackground;
          margin-right: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
          > input[type="file"] {
            position: absolute;
            left: -10000px;
            opacity: 0;
          }
        }
        &__remove {
          position: absolute;
          top: 8px;
          right: 8px;
          @extend %appearance-none;
          background: $mediumBlack;
          border-radius: 14px;
          height: 24px;
          width: 24px;
          padding: 0;
          border: 0;
          cursor: pointer;
          > img {
            width: 12px;
            margin-top: 4px;
          }
        }
      }
      &__file {
        &__container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
        &__upload {
          > input[type="file"] {
            position: absolute;
            opacity: 0;
            left: -10000px;
          }
          background: $grayBackground;
          height: 46px;
          padding: 0 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          @include get-font($f14, $fw-m, $black, $lh);
          cursor: pointer;
          box-sizing: border-box;
        }
        margin-right: 16px;
        > a {
          padding: 0 12px;
          margin-bottom: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          background: $lightestBlue;
          text-decoration: none;
          margin-right: 16px;
          border-radius: 4px;
          flex-shrink: 0;
          > span {
            @include get-font($f14, $fw-m, $blue, $lh);
          }
          > img {
            margin-left: 8px;
            width: 16px;
          }
        }
        &__remove {
          @extend %appearance-none;
          border: 0;
          background: transparent;
          cursor: pointer;
          padding: 0;
        }
      }
      .survey__playable__input {
        background: $grayBackground;
        box-shadow: none;
      }
    }
    &__mandatory {
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid $grayBackground;
      &__title {
        @include get-font($f12, $fw-b, $black, $lh);
        letter-spacing: 0.6px;
      }
      &__fields {
        margin-top: 16px;
        padding: 8px 6px;
        background: $grayBackground;
        border-radius: 10px;
        margin-right: 12px;
        @include get-font($f16, $fw-r, $black, $lh);
        &__list {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    &__copyicon {
      display: flex;
      margin-top: 24px;
      &__img {
        margin-left: auto;
      }
    }

    &__clearselection {
      display: flex;
      margin-top: 24px;
      &__text {
        margin-left: auto;
        @include get-font($f14, $fw-m, $lightestGray, $lh);
      }
    }
    &__rangequestion {
      display: flex;
      margin-top: 24px;
      align-items: center;
      justify-content: center;

      &__select-div {
        border: 1px solid #D1D1D1;
        padding: 8px 12px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        margin-top: 20px;
      }

      &__minlabel {
        margin-top: 24px;
        margin-right: 24px;
        padding-left: 10px;
        @include get-font($f14, $fw-r, $lightestGray, $lh);
      }
      &__rangediv {
        display: flex;
      }
      &__maxlabel {
        margin-top: 24px;
        margin-left: 24px;
        padding-right: 10px;
        @include get-font($f14, $fw-r, $lightestGray, $lh);
      }

      &__radiolist {
        display: flex;
        flex-direction: column;
        text-align: center;
        &__label {
          @include get-font($f14, $fw-r, $black, $lh);
        }
        &__radiobutton {
          margin-right: 8px;
          margin-left: 8px;
          margin-top: 8px;
        }
      }

      &__list {
        margin-top: 16px;
      }
      &__option {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &__title {
          min-width: 70px;
          @include get-font($f16, $fw-r, $black, $lh);
        }
        &__dropdown {
          width: 100%;
          &__control {
            border-radius: 10px !important;
            min-height: 40px !important;
            padding: 2px 0;
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            background-color: white !important;

            &--is-focused{
              border-color: rgb(204, 204, 204) !important;
              box-shadow: none !important;
            }

            .selected-value{
              img{
                width:20px;
              }
              display: flex;
              align-items: center;
              gap: 10px;
              @include get-font($f16, $fw-r, $black, $lh);
              b{
                font-weight: 500;
              }
            }
          }
          &__placeholder {
            font-weight: 400;
            opacity: 0.5;
            font-size: 16px;
            line-height: 21px;
            top: 47% !important;
          }

          &__value-container {
            height: 40px !important;
          }
          &__indicator {
            color: rgb(102, 102, 102) !important;
          }

          &__option {
            display: flex;
            padding: 15px 0px;
            margin: 0px 10px;
            &:hover{
              background-color: $backgroundGray;
            }
            &:not(:last-child){
              border-bottom: 1px solid $imageWrapperBackgroundGray;
            }

            &__radiobutton {
              margin-right: 8px;
              margin-left: 6px;
              width: 20px;
            }
            &__textdiv {
                @include get-font($f14, $fw-r, $black, $lh);
                b{
                  font-weight: 500;
                }
            }

            &:hover {
              cursor: pointer;
            }
          }
          &__menu{
            box-shadow: none !important;
            &-list{
              @include getDefaultScrollbar();
              scrollbar-color: #ededed #ffffff;
            }
          }
        }
      }
    }
    &__rankquestion {
      &__list {
        margin-top: 36px;
      }
      &__option {
        display: flex;
        margin-bottom: 12px;

        &__title {
          margin-top: 12px;
          margin-right: 36px;
          width: 70px;
          @include get-font($f16, $fw-r, $black, $lh);
        }
        &__dropdown {
          width: 100%;
          &__control {
            border-radius: 10px !important;
            min-height: 50px !important;
            padding: 2px 0;
          }

          &__value-container {
            height: 40px !important;
          }

          &__indicator-separator {
            background-color: transparent !important;
          }

          &__option {
            display: flex;
            padding: 6px 10px;

            &__radiobutton {
              margin-right: 8px;
              margin-left: 6px;
              width: 20px;
            }
            &__textdiv {
              display: flex;
              flex-direction: column;

              &__title {
                &__norank {
                  @include get-font($f14, $fw-r, $black, $lh);
                }
                @include get-font($f14, $fw-r, $black, $lh);
              }
              &__rank {
                @include get-font($f12, $fw-sb, $green, $lh);
              }
            }

            &:hover {
              border-color: rgb(38, 132, 255);
              background-color: $aquaBlue;
              cursor: pointer;
            }
          }
        }
      }
    }
    &__multiselectradio {
      margin-top: 24px;

      &__header {
        top: 4px;
      }
      &__headertitle {
        padding: 0px 14px 14px 14px !important;
        text-align: center;
        background-color: $white;
        @include get-font($f14, $fw-r, $black, $lh);
        word-wrap: break-word;
      }

      &__row {
        [data-sticky-last-left-td] {
          box-shadow: 0px 0px 0px !important;
        }
      }
      &__rowtitle {
        padding: 14px !important;
        background-color: $white;
        word-wrap: break-word;
        @include get-font($f14, $fw-r, $black, $lh);
      }
      &__rowinput {
        padding: 14px;
        background-color: $white;
        text-align: center;
      }
    }
  }
  &__reorder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 30px);
    background: $white;
    border-top: 1.5px solid $gray;
    border-bottom: 1.5px solid $gray;
    padding: 10px 10px 10px 24px;
    &__draggable {
      text-align: center;
      padding-right: 16px;
    }
    &__listParent {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  &__sectionParent {
    margin-top: 24px;
  }
  &__sectionBorderStylesForError {
    &__borderTop {
      border-top-color: red;
      border-top-style: solid;
      border-top-width: 1px;
      border-right-color: red;
      border-right-style: solid;
      border-right-width: 1px;
      border-left-color: red;
      border-left-style: solid;
      border-left-width: 1px;
      border-image-source: initial;
      border-image-slice: initial;
      border-image-width: initial;
      border-image-outset: initial;
      border-image-repeat: initial;
    }
    &__borderBottom {
      border-right-color: red;
      border-right-style: solid;
      border-right-width: 1px;
      border-bottom-color: red;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-left-color: red;
      border-left-style: solid;
      border-left-width: 1px;
      border-image-source: initial;
      border-image-slice: initial;
      border-image-width: initial;
      border-image-outset: initial;
      border-image-repeat: initial;
    }
  }
  &__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    background: #00a146;
    border-radius: 10px 10px 0px 0px;
    &__title {
      @include get-font($f16, $fw-m, $white, $lh);
    }
    &__details {
      background: $white;
      padding: 16px;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .collaboration-form__group {
        margin-bottom: 24px;
      }
      .collaboration-form__input-container--media {
        border: 0;
        padding: 0;
        box-shadow: none;
      }
      .collaboration-form__input-container__media-group {
        margin-bottom: 24px;
      }
      .tox-edit-area__iframe {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .survey__details__description {
        margin-top: 12px;
        font-size: $f14;
        font-weight: $fw-m;
      }
      &__action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__btn {
          border: 0;
          height: 40px;
          padding: 0 16px;
          line-height: 40px;
          @include get-font($f16, $fw-sb, $white);
          @include transitionEase();
          cursor: pointer;
          border-radius: 10px;
          outline: none;
          &--primary {
            background: $green;
            &:hover {
              background: $darkGreen;
            }
          }
          &--secondary {
            padding: 0;
            background: transparent;
            color: $removeRed;
            &:hover {
              color: $red;
            }
          }
          &--disabled {
            cursor: not-allowed;
            opacity: 0.65;
          }
        }
      }
    }
    &__dropdown {
      &__btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        outline: inherit;
        cursor: pointer;
        transform: rotate(90deg);
        color: white;
        font-weight: 800;
        &__text {
          @include get-font($f16, $fw-sb, $white, $lh);
        }
      }
      &__content {
        position: absolute !important;
        width: 150px !important;
      }
      &__mobileDropdown {
        left: 180px !important;
      }
    }
    &__placeholder {
      @include get-font($f22, 700, $placeholderBlack, $lhl);
      word-break: break-word;
      max-width: 400px;
      cursor: pointer;
    }
    &__placeholderSmall {
      @include get-font($f14, $fw-m, $placeholderBlack, $lh22);
      word-break: break-word;
      max-width: 400px;
    }
  }
  &__progressBar {
    width: 100%;
    background-color: $backgroundGray;
    border-radius: 40;
    margin: 50;
    position: fixed;
    z-index: 100;
    margin: 0;
    &__child {
      height: 100%;
      border-radius: 40;
      text-align: right;
    }
    &__progressText {
      padding: 10;
      color: $black;
      font-weight: 900;
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .survey {
    &__playable {
      &__select {
        &__image {
          &__title {
            padding: 8px;
          }
        }
      }
      &__mandatory {
        padding-top: 8px;
        margin-top: 8px;
        &__fields {
          margin-top: 8px;
          padding: 8px 6px;
          background: $grayBackground;
          border-radius: 10px;
          margin-right: 8px;
          font-size: $f14;
        }
      }
      &__footer__nextBtn{
        width: fit-content;
        padding: 16px;
      }
      &__rankquestion{
        &__option{
          flex-direction: column;
          gap: 12px;
          &__title{
            width: auto;
          }
        }
        &__list{
          margin-top: 8px;
        }
      }
     
    }
  }
}
