.feed-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-top: 32px;
  margin-bottom: 32px;
  &__left {
    width: $sideColumnWidth;
    margin-right: 16px;
    height: 200px;
    padding-top: 46px;
  }
  &__right {
    width: $sideColumnWidth;
    margin-left: 16px;
    padding-top: 46px;
  }
  &__center {
    width: $centerColumnWidth;
    position: relative;
    &__new-post-popup {
      position: fixed;
      @extend %appearance-none;
      @extend %border-box-shadow;
      height: 32px;
      border-radius: 16px;
      background: $white;
      cursor: pointer;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
      padding: 0 8px;
      &__text {
        @include get-font($f14, $fw-m, $black, normal);
      }
    }
    // comments ui improvement...code added here as .feed-container__center is used in landing & feed
    .feed-card{
      .post-comments-list{
        &__details__header{
          margin-bottom: 0;
          &__container{
            position: relative; // acting as parent for btn absolute
            padding-right: 40px; // added to leave gap on right as button is now absolute 
          }
        }
        &__action{
          position: absolute; // height of btn can't be reduced as it will affect hover state hence adjusted using position
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  @media screen and (max-width: $onlyMobile) {
    &__center {
      width: calc(100%);
      padding: 0 16px;
      box-sizing: border-box;
    }
  }
}

.feed-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  &__item {
    margin-bottom: 1em;
    width: 100%;
    border-radius: 10px;
    background: $white;
    box-shadow: 0 2px 4px 0 $superLightBlack;
    border: solid 0.5px $superLightBlack;
    box-sizing: border-box;
    &--secondary {
      border: 0;
      box-shadow: none;
      background: none;
    }
  }
}

.feed-card,
.shared-card {
  .user-block {
    padding: 16px 16px 0 16px;
    &__details {
      max-width: calc(100% - 32px);
      width: 100%;
    }
    &__info {
      max-width: calc(100% - 48px);
      width: 100%;
    }
  }
}

.shared-card {
  &__text {
    margin-bottom: 16px;
  }
  &__original-card {
    width: calc(100% - 32px);
    margin: 16px 16px 0;
    &--full {
      width: 100%;
      margin: 0;
    }
    &__container {
      border-radius: 10px;
      &--spacing{
        padding-bottom: 16px;
      }
      overflow: hidden;
      border: 0.5px solid $superLightBlack;
      box-shadow: 0 2px 4px 0 $superLightBlack;
      &--full {
        border: 0;
        border-radius: 0;
        box-shadow: none;
      }
      .post-details {
        &__text {
          padding-bottom: 16px;
        }
      }
      .photo-details__caption {
        margin-bottom: 0;
      }
      .photo-details__container__slider {
        padding-bottom: 16px;
      }
    }
  }
}

.user-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  &__details {
    display: flex;
    text-decoration: none;
    align-items: center;
  }
  &__image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
    margin-right: 8px;
    overflow: hidden;
    box-sizing: border-box;
    border: 0.5px solid $grayBackground;
    flex-shrink: 0;
    display: block;
    &.company{
      border-radius: 8px
    }
  }
  &__info {
    &__name {
      @include get-font($f14, $fw-m, $black, $lh);
      margin-bottom: 1px;
      text-decoration: none;
      word-break: break-word;
      &--verified {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
    &__secondary {
      @include get-font($f12, $fw-r, $lightBlack, $lh);
      &--shared-icon {
        vertical-align: middle;
        margin-right: 6px;
        transform: scaleX(-1);
      }
      &--divider {
        margin: 0 4px;
      }
      text-decoration: none;
      &--link {
        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
    }
  }
  &__action {
    position: relative;
    &__btn {
      @extend %appearance-none;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border: 0;
      background: $white;
      cursor: pointer;
      rotate: 90deg;
      @include transitionEase(#{background});
      &:hover,
      &--active {
        background: $grayBackground;
      }
      &.no-rotate{
        rotate: unset;
      }
      &:active {
        background: $lightestBlack;
      }
      &__text {
        @include get-font($f14, $fw-m, $black, $lh);
      }
    }
    &__container {
      position: absolute;
      z-index: 21;
      margin-left: -224px;
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        padding: 16px 0;
        width: 256px;
        background: $white;
        @extend %border-box-shadow;
        border-radius: 10px;
        border-top-right-radius: 0;
        &__item {
          &__btn {
            @extend %appearance-none;
            padding: 8px 12px;
            box-sizing: border-box;
            border: 0;
            cursor: pointer;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: transparent;
            &__text {
              @include get-font($f16, $fw-r, $black, $lh);
              &--red {
                color: $removeRed;
              }
            }
            &__icon {
              margin-right: 8px;
            }
            &__icon1 {
              margin-right: 8px;
              color: #DD2E1F;
              // background-color: #DD2E1F;
            }
            &__icon2 {
              // margin-right: 8px;
              text-align: center;
              // color: #DD2E1F;
              // background-color: #DD2E1F;
            }
            &:hover,
            &:active {
              background: $grayBackground;
            }
          }
        }
      }
    }
  }
}

.post-details {
  // margin-bottom: 20px;
  &__text {
    @include get-font($f14, $fw-r, $black, $lh);
    display: block;
    padding: 0 16px;
  }
  &__link {
    @include get-font(inherit, inherit, $blue, $lh);
    text-decoration: none;
    cursor: pointer;
    &--inline-block {
      display: inline;
    }
    &--tagged {
      background: $chatPink;
      color: $pink;
      padding: 0 2px;
      text-decoration: none;
      display: inline;
    }
  }
  &__video-container {
    margin-top: 12px;
    width: 100%;
    height: 320px;
    background: $black;
    text-align: center;
    position: relative;
    &__icon {
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 100%;
    }
    &__play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &--youtube {
      position: relative;
      padding-bottom: 56.25%; // 16:9
      padding-top: 25px;
      height: 0;
      margin-top: 0px;
      &__player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        margin-top: 0px;
      }
    }
  }
}

.feed-card-preview {
  text-decoration: none;
  &--small {
    padding: 16px;
    background: $lightBg;
    display: flex;
    margin-top: 12px;
    &__image {
      width: 92px;
      height: 92px;
      border-radius: 5px;
      object-fit: cover;
      display: block;
    }
    &__info {
      margin-left: 12px;
      text-decoration: none;
      flex: 1;
      &--title {
        @include get-font($f16, $fw-m, $black, $lh);
        margin-bottom: 4px;
      }
      &--description {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-bottom: 4px;
        max-height: 68px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &--sitename {
        @include get-font($f12, $fw-r, $blue, $lh);
      }
    }
  }
}
.photo-details {
  &__caption {
    margin-bottom: 16px;
  }
  &__container {
    width: 100%;
    text-align: center;
    position: relative;
    &__image {
      width: auto;
      height: auto;
      max-width: 568px;
      max-height: 100%;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
    &__slider {
      width: 99%;
      margin-left: auto;
      margin-right: auto;
      @media only screen and (max-width: $onlyMobile) {
        width: 100%;
      }
      .slick-slider {
        height: 100%;
      }
      &__slide {
        position: relative;
        width: 100%;
        outline: 0;
        img {
          max-width: 100%;
          outline: 0;
          margin: 0 auto;
          object-fit: contain;
          cursor: pointer;
        }
      }
      &__arrow {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        @extend %appearance-none;
        @extend %border-box-shadow;
        background: $white;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        text-align: center;
        padding: 0;
        cursor: pointer;
        @include transitionEase(#{background});
        &:hover {
          background: $backgroundGray;
        }
        &--left {
          left: 0;
          margin-left: 16px;
        }
        &--right {
          right: 0;
          margin-right: 16px;
        }
        &--disabled {
          opacity: 0.5;
          &:hover {
            background: $white !important;
          }
        }
        &__icon {
          vertical-align: middle;
          width: 16px;
          height: 16px;
          &--left {
            transform: rotate(180deg);
          }
        }
      }
      .slick-dots-custom {
        bottom: 24px;
        > li {
          width: 10px;
          height: 10px;
          margin: 0 1.5px;
        }
      }
    }
  }
}
.post-action-bar {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px;
  &__meta {
    display: flex;
    align-items: center;
    @include get-font($f14, $fw-m, $black, $lh);
    &__seprator {
      margin: 0 14px;
      &--quiz {
        margin: 0 5px;
      }
    }
    &__btn {
      @extend %appearance-none;
      border-radius: 3px;
      background: $white;
      border: 0;
      @include transitionEase(#{background});
      margin: 0;
      padding: 0 4px;
      height: 24px;
      &--default {
        &:hover {
          background: $superLightestBlue;
        }
        &:active {
          background: rgba($superLightestBlue, 0.2);
        }
      }
      &--disabled {
        opacity: 0.6;
        pointer-events: none;
      }
      &--pink {
        &:hover {
          background: $lightestPink;
        }
        &:active {
          background: $lighterPink;
        }
      }
      &__text {
        @include get-font($f14, $fw-m, $black, $lh);
      }
    }
  }
  &__action {
    display: flex;
    @include get-font($f14, $fw-r, $black, $lh);
    &__popup-container {
      position: relative;
      .post-action-bar__action__btn {
        margin: 0;
      }
      .post-share-popup-up {
        position: absolute;
        @media only screen and (max-width: $onlyMobile) {
          //margin-left: -256px;
          margin-left: -110px;
        }
      }
    }
    &__btn {
      @extend %appearance-none;
      border-radius: 8px;
      height: 32px;
      padding: 4px 12px 6px;
      margin: 0 1.5px;
      box-sizing: border-box;
      border: 0;
      background: $white;
      @include transitionEase(#{background});
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &__image {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 8px;
      }
      &__like {
        width: 82.84px;
      }
      &__text {
        @include get-font($f14, $fw-m, $black, $lh);
      }
      &--pink {
        &:hover {
          background: $lightestPink !important;
        }
        &:active {
          background: $lighterPink !important;
        }
      }
      &--disabled {
        &:hover,
        &:active {
          background: $white !important;
        }
        opacity: 0.7 !important;
        background: $white !important;
      }
      &:hover,
      &--active {
        background: $grayBackground;
      }
      &:active {
        background: $lightestBlack;
      }
      @media screen and (max-width: $onlyMobile) {
        padding: 4px 9px 6px;
        &__text {
          display: none;
        }
        &__like {
          width: auto;
        }
        &__image {
          margin: 0;
        }
      }
    }
  }
}

.collaborate-details {
  background: $superLightestBlue;
  padding: 24px 16px 16px 16px;
  display: block;
  text-decoration: none;
  &__container {
    &__title {
      @include get-font($f12, $fw-b, $blue, $lh);
      text-transform: uppercase;
      margin: 0 0 12px 0;
      word-break: break-word;
    }
    &__looking-for {
      @include get-font($f16, $fw-sb, $black, $lh);
      margin-bottom: 8px;
      .post-details__link {
        font-weight: inherit;
        font-size: inherit;
      }
    }
    &__description {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      margin-bottom: 16px;
      word-break: break-word;
      white-space: pre-wrap;
      max-height: 104px;
      overflow: hidden;
    }
    &__view-more {
      @extend %appearance-none;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      background-color: $mediumBlue;
      @include get-font($f12, $fw-b, $white, $lh);
      letter-spacing: 0.6px;
      cursor: pointer;
      @include transitionEase(#{background});
      border: 0;
      &:hover,
      &:focus,
      &:active {
        background: $blue;
      }
    }
  }
}

.productreview-details {
  &.collaborate-details__container__title {
    color: $lightestGray;
  }

  &.collaborate-details__container__view-more {
    background-color: $yellow;
    color: $black;
  }

  &.collaborate-details {
    background: $tastingOptionYellow;
  }
}

.read-more-link {
  color: $blue !important;
  cursor: pointer;
  display: inline-block;
}

@media only screen and (max-width: $onlyMobile) {
  .post-details {
    &__video-container {
      height: auto;
    }
  }
}

.retry-container {
  text-align: center;
  margin-top: 16px;

  &.feed{
    border-radius: 10px;
    background-color:$white;
    padding: 32px 0; 
  }
  .icon{
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover;
    &__container{
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 25%;
      margin: 0 auto 8px;
      
    }
  }

  .title{
    @include get-font($f18, $fw-sb, $lightBlack, $lh);
    margin: 0 0 8px 0;
  }
  .subtitle{
    @include get-font($f14, $fw-r, rgba($black,.7), $lh); 
  }
  .action{
    @extend %appearance-none;
    margin: 16px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 16px;
    background: rgba($black, 0.8);
    @include get-font($f14, $fw-m, $white, $lh);
    border-radius: 40px;
    &:hover,
    &:focus,
    &:active {
      background: $black;
    }
    border: 0;
    img {
      display: block;
      margin-right: 6px;
    }
  }
}

.end-container {
  width: 100%;
  max-width: 570px;
  height: 45px;
  @include get-font($f14, $fw-sb, $lightBlack, $lh);
  line-height: 45px;
  text-align: center;
  border-radius: 10px;
  background: $grayBackground;
}

.feed-passbook {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 $superLightBlack;
  margin-bottom: 24px;

  &__amountrow {
    display: flex;
    padding: 16px;
    background: $white;
    border-radius: 10px 10px 0 0;

    &__symbol {
      background-color: $red;
      padding: 2px 10px;
      border-radius: 4px;
      @include get-font($f18, $fw-sb, $white, $lh);
    }
    &__text {
      margin-left: 8px;
      margin-top: 6px;
      @include get-font($f14, $fw-sb, $black, $lh);
    }

    &__amount {
      margin-left: auto;
      margin-top: 3px;
      background-color: $black;
      border-radius: 20px;
      padding: 4px 10px;
      max-width: 72px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @include get-font($f14, $fw-sb, $white, $lh);

      &__disabled {
        &.feed-passbook__amountrow__amount {
          opacity: 0.2;
        }
      }
    }
  }

  &__ctarow {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
    background-color: $black;
    &__link {
      padding: 8px 0px;

      &__text {
        margin-right: 4px;
        vertical-align: bottom;
        @include get-font($f14, $fw-sb, $focusedGray, $lh);

        &__disabled {
          &.feed-passbook__ctarow__link__text {
            color: $white;
          }
        }
      }

      &__icon path {
        fill: $focusedGray;
      }
    }
  }
}

.feed-product-available {
  display: flex;
  background-color: $black;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
  @media only screen and (max-width: $onlyMobile) {
    margin-bottom: 16px;
  }

  &__img {
    background-color: $yellow;
    padding: 2px 10px;
    border-radius: 4px;
  }

  &__text {
    margin-left: 12px;
    display: flex;
    flex-direction: column;

    &__title {
      @include get-font($f14, $fw-sb, $white, $lh);
    }
    &__cta {
      &__text {
        margin-right: 4px;
        vertical-align: top;
        @include get-font($f12, $fw-sb, $focusedGray, $lh);
      }
      &__icon {
        path {
          fill: $red;
        }
      }
    }
  }
}

.feed-bannerimage {
  margin-bottom: 24px;
  img {
    width: 100%;
  }
}

.feed-bigbanner {
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px 0 $superLightBlack;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    border-radius: 8px;
    height: 456px;
  }

  @media only screen and (max-width: $onlyMobile) {
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      border-radius: 8px;
      height: 286px;
    }
  }

  &:hover {
    .suggestions__arrow {
      visibility: visible;
      opacity: 1;
    }
  }
  .suggestions__arrow--prev {
    margin-top: -10px;
    margin-left: 12px;
  }
  .suggestions__arrow--next {
    margin-top: -10px;
    margin-right: 12px;
  }

  &__slideritem {
    border-radius: 8px;

    img {
      width: 100%;
      border-radius: 8px;
      aspect-ratio: 1.25;
      background: $white;
    }
  }

  &__ul {
    margin: 0px 24px 6px 0px;
    padding-top: 6px;
    height: 10px;

    li {
      @media only screen and (max-width: $onlyMobile) {
        width: 4%;
      }
    }
  }

  .suggestions__arrow {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
  }

  .slick-active {
    width: 18px;
    top: 0px;
    overflow: hidden;

    .feed-bigbanner__customdot {
      width: 18px;
      top: 0px;
      overflow: hidden;
      background-color: $white;
    }

    @media only screen and (max-width: $onlyMobile) {
      width: 4%;

      .feed-bigbanner__customdot {
        width: 100%;
      }
    }
  }

  &__customdot {
    height: 4px;
    width: 18px;
    background-color: $chatSender;
    border-radius: 10px;
    position: relative;

    @media only screen and (max-width: $onlyMobile) {
      width: 100%;
    }
  }
}

.gif_icon_feed{
  position: relative ;
  left: 16px ;
  bottom: 24px ;
 
}

@media only screen and (max-width: $onlyMobile) {
  .shared-card {
      .user-block{
          &__action{
              &__container{
                  margin-left: auto;
                  right: 0px;
                  margin-right: 18px;
                  &__list{
                      width: max-content;
                      max-width: fit-content;
                      padding-right: 20px;
                  }
              }
          }
      }
  }
}