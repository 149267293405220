.survey {
  &__reports {
    &__remove-content {
      background: transparent;
      box-shadow: none;
      border: 0;
      padding: 0;
    }
    &__multiSubmissionScroller {
      width: 100%;
      &__btn {
        @extend %appearance-none;
        border: 0;
        background: $black;
        padding: 6px 8px;
        margin: 0 16px;
        &:last-child{
          margin-right: 0;
        }
        width: 32px;
        border-radius: 4px;
        &--left {
          transform: left;
        }
        &--disabled {
          background: $gray;
          cursor: not-allowed;
          pointer-events: none;
        }
        &--img {
          height: 18px;
          padding: 0;
          margin: 0;
          top: 1.5px;
          position: relative;
          &--left {
            transform: rotate(-180deg);
          }
        }
      }
      &__text {
        margin-left: 8px;
        @include get-font($f14, $fw-r, $copiedBackground);
      }
      &__secondaryText {
        @include get-font($f14, $fw-m, $copiedBackground);
      }
    }
    &__header {
      padding-bottom: 16px;
      border-bottom: 1px solid $grayBackground;
      &--without-border {
        padding-bottom: 0;
        border-bottom: 0;
      }
      &__sectionList{
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        @include getDefaultScrollbar();
        white-space: nowrap;
        overflow: auto;
      }
      &__sectionItem{
        margin-right: 12px;
        padding: 8px 12px;
        border-radius: 8px;
        cursor: pointer;
        background-color: $grayBackground;
        color: $black;
        &--selected {
          background-color: $black;
          color: $white;
        }
      } 
      &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        // margin-bottom: 1em;
        &__title {
          @include get-font($f14, $fw-r, $green);
        }
        &__image {
          margin-right: 8px;
        }
        &:hover {
          text-decoration: underline;
          color: $green;
        }
      }
      &__action {
        display: flex;
        align-items: flex-end;
        margin-top: 24px;
        justify-content: space-between;
        &__title {
          @include get-font($f12, $fw-b, $black, $lh);
          letter-spacing: 0.6px;
        }
        &__btn {
          @extend %appearance-none;
          background: $white;
          border-radius: 10px;
          border: 1.5px solid $green;
          cursor: pointer;
          @include get-font($f12, $fw-b, $green, $lh);
          letter-spacing: 0.6px;
          padding: 8px 16px;
          min-width: fit-content;
          &:disabled{
            cursor: no-drop;
            opacity: .5;
          }
        }
        &__container{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      &__container {
        background: $white;
        @include form-input-border-shadow();
        padding: 24px;
        border-radius: 10px;
      }
    }
    &__sectionTitle {
      width: auto;
      background: #00a146;
      border-radius: 10px 10px 0px 0px;
      padding: 12px 16px;
      @include get-font($f16, $fw-b, $white, $lh);
    }
    &__section {
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-left: 16px;
      padding: 24px 24px 16px 24px;
      box-sizing: border-box;
      overflow: hidden;

      &__header {
        &--without-border {
          padding-bottom: 0;
          border-bottom: 0;
        }
        &__link {
          display: flex;
          align-items: center;
          text-decoration: none;
          width: fit-content;
          margin-bottom: 1em;
          &__title {
            @include get-font($f14, $fw-r, $green); 
          }
          &__image {
            margin-right: 8px;
          }
          &:hover {
            text-decoration: underline;
            color: $green;
          }
        }
        &__action {
          display: flex;
          align-items: flex-end;
          margin-top: 24px;
          justify-content: space-between;
          &__title {
            @include get-font($f12, $fw-b, $black, $lh);
            letter-spacing: 0.6px;
          }
          &__btn {
            @extend %appearance-none;
            background: $white;
            border-radius: 10px;
            border: 1.5px solid $green;
            cursor: pointer;
            @include get-font($f12, $fw-b, $green, $lh);
            letter-spacing: 0.6px;
            padding: 8px 16px;
          }
        }
        &__container {
          background: $white;
          @include form-input-border-shadow();
          padding: 24px;
          border-radius: 10px;
        }
      }
      &__responses {
        width: 100%;
        flex: 1 1;
      }
    }
    &__perSection {
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      box-sizing: border-box;
      overflow: hidden;
      margin-bottom: 16px;
    }
    &__semiSection {
      border-radius: 10px;
      box-sizing: border-box;
    }
    &__sectionQuestions {
      padding: 48px 24px 0px 24px;
    }
    &__summary {
      &__view-all {
        &__container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__btn {
          @extend %appearance-none;
          background: $white;
          cursor: pointer;
          text-transform: uppercase;
          border: 0;
          padding: 0;
          @include get-font($f12, $fw-b, $blue, $lh);
          letter-spacing: 0.6px;
        }
      }
      &__question {
        list-style: none;
        margin: 0;
        padding: 24px;
        &__details {
          &__title {
            @include get-font($f16, $fw-m, $black, $lh);
            display: flex;
          }
          &__helper {
            margin-top: 6px;
            @include get-font($f14, $fw-r, $lightBlack, $lh);
          }
        }
        &__item {
          margin-bottom: 48px;
        }
        &__pill {
          display: flex;
          align-items: center;
          margin-top: 16px;
          justify-content: space-between;
          &__list {
            list-style: none;
            margin: 0;
            padding: 0;
          }
          &__data {
            min-height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 100%;
            background-color: $grayBackground;
            border-radius: 22px;
            position: relative;
            overflow: hidden;
            padding: 0 16px;
            box-sizing: border-box;
          }
          &__filled {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $lightTeal;
          }
          &__title {
            position: relative;
            margin-right: 8px;
            @include get-font($f16, $fw-m, $black);
          }
          &__helper {
            flex-shrink: 0;
            position: relative;
            @include get-font($f12, $fw-r, $lightBlack);
            > span {
              display: inline-flex;
              width: 24px;
              margin-left: 8px;
            }
          }
          &__percentage {
            margin-left: 8px;
            @include get-font($f14, $fw-m, $black, $lh);
            flex-shrink: unset;
            z-index: 1;
            word-break: keep-all;
            min-width: 34px;
            text-align: right;
          }
          &__chevron {
            height: 24px;
            border-radius: 24px;
            background: $grayBackground;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &__answerpills {
        display: flex;
        margin-top: 24px;
        padding-bottom: 24px;
        overflow-x: auto;

        &__answer {
          display: flex;
          margin-right: 16px;
          flex-direction: column;
          align-items: center;
          vertical-align: middle;
          flex: 0 0 auto;

          &__title {
            @include get-font($f14, $fw-r, $black, $lh);
          }
          &__percent {
            padding: 4px 14px;
            margin-top: 6px;
            border-radius: 20px;
            border: solid 0.5px $tastingCardShadow;
            cursor: default;
            min-width: 48px;
            max-width: 48px;
            text-align: center;
            @include get-font($f14, $fw-m, $black, $lh);
          }
        }
      }
      &__multiselect {
        margin-top: 24px;
        &__row {
          background-color: $white;
          display: flex !important;
          align-items: center;
          vertical-align: middle;

          &__answerpill {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      &__rankquestion {
        margin-top: 24px;
      }
      &__media{
        &--item{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 22px;
          margin-top: 16px;
          padding: 10px 16px;
          max-width: calc(100% - 74px);
          background-color: #cbcbde;
          cursor: pointer;
          > b{
            @include get-font($f16, $fw-m, $black, $f24);
          }
          span{
            @include get-font($f12, $fw-r, $lightestGray, $f18);

            b{
              font-weight: $fw-sb;
            }
          }

          .action{
            display: flex;
            align-items: center;
            gap: 8px;

            .chevron{
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              width: 24px;
              border-radius: 100%;
              background-color: rgba($color: $white, $alpha: .4);
            }
          }

          &:hover{
            .chevron{
              background-color: rgba($color: $white, $alpha: .9);
            }
          }
        }
        .view-all{
          all: unset;
          display: block;
          margin:24px auto auto auto;
          width: fit-content;
          background-color: transparent;
          @include get-font($f12,$fw-b,$green,$f18);
          cursor: pointer;
        }
      }
      .pricing{ //pricing survey stylings  
        &-info{
          &__tooltip{
            &.__react_component_tooltip{
              box-shadow: 0 1px 4px #0C0C0D1A;
              border: 1px solid #D9D9D9;
              max-width: 300px;
              text-align: left;
              border-radius: 8px;
              padding-top: 16px;
              b{
                font-size: 16px;
                color: $black;
              }
              ul{
                padding: 0 16px;
                margin: 8px 0;
              }
              li,p{
                @include get-font($f14,$fw-r,$black,$lh);
                margin: 8px 0;

              }
            }
          }
        }
      }
    }
    &__users {
      padding: 16px 0;
      box-sizing: border-box;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        height: 100%;
        top: 0;
        left: -24px;
        content: "";
        border-bottom: 1px solid $grayBackground;
        width: calc(100% + 48px);
      }
      &__moreInfo {
        @include get-font($f12, $fw-r, $black, $f18);
        opacity: 0.6;
        margin-top: 4px;
      }
      &__details {
        display: flex;
        align-items: center;
      }
      &__avatar {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        margin-right: 8px;
      }
      &__title {
        @include get-font($f14, $fw-m, $black, $lhOnboarding);
        text-decoration: none;
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      &__arrow {
        height: 16px;
        width: auto;
      }
    }
    &__text-answers {
      &--container {
        padding-bottom: 12px !important;
        .scrollable-modal__header--paddingLessContentWithNoteText{
          padding-bottom: 0px !important;
          .scrollable-modal__header__note-text{
            margin-bottom: 16px;
          }
          &::after{
            top: auto;
          }
        }
        .scrollable-modal__modal-overflow{
          max-height: 74vh;
        }
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        padding-left: 20px;
      }
      margin-bottom: 12px;
      padding-top: 12px;
      &__title {
        margin-top: 8px;
        @include get-font($f14, $fw-r, $black, $lh);
        word-break: break-word;
      }
    }
    &__media {
      &__pills {
        &__list {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;
          list-style-type: none;
        }
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        box-sizing: border-box;
        background: $grayBackground;
        @include get-font($f14, $fw-m, $lightBlack);
        margin-right: 2px;
        cursor: pointer;
        @include transitionEase(#{background, color});
        &--active {
          background: $green;
          color: $white;
          cursor: default;
        }
        &:hover {
          background: $green;
          color: $white;
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          margin-right: 0;
        }
        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
      &__links {
        &__list {
          list-style: none;
          margin: 0;
          padding: 0;
          margin-top: 16px;
          box-sizing: border-box;
          width: 100%;
          li + li{
            margin-top: 16px;
          }
        }
        &--blue {
          background: $superLightestBlue;
          .survey__reports__media__links__title {
            color: $blue;
          }
        }
        height: 44px;
        border-radius: 22px;
        background-color: $grayBackground;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        padding: 0 16px;
        box-sizing: border-box;
        &__title {
          @include get-font($f14, $fw-m, $black, $lh);
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 16px;
        }
        &__meta {
          flex-shrink: unset;
          > span {
            @include get-font($f14, $fw-m, $lightBlack);
          }
          > img {
            margin-left: 16px;
          }
        }
      }
      &__modal{
        .scrollable-modal__header {
          align-items: flex-start;
          gap: 5px;
        }
        .scrollable-modal__modal-overflow{
          min-height: 40vh;
        }
        .survey__reports__summary__question__details__title{
          font-size: $f18;
          font-weight: $fw-b;
        }
        .survey__reports__header__action{
          margin: 0;
        }
        .report-modal__selfie{
          display: grid;
          grid-template-columns: repeat(auto-fill, 84px);
          justify-content: space-between;
          grid-gap: 20px;
          &__item{
            margin: 0;
          }
        }
        &--content{
          max-height: 50vh;
          overflow-y: auto;
          @include getDefaultScrollbar();
          margin-top: 8px;
        }
        .see-all-container__empty-field p{
          margin-top: 12px;
          @include get-font($f18,$fw-sb,rgba($copiedBackground,.6));
        }
      }
    }
    &__loader {
      margin:16px 33% !important;
    }
    &__filter {
      &--question {
        &--modal {
          &__container {
            padding: 0;
            .default-modal {
              &__header {
                align-items: flex-start;
                gap: 10px;
                padding: 16px;
                margin-bottom: 0;
                box-shadow: 0px 2px 4px rgba(23, 23, 23, 0.1);
              }
            }
            .custom-header {
              &__wrapper {
                display: flex;
                align-items: flex-start;
                gap: 12px;
              }
              .title {
                margin: 0;
                @include get-font($f18, $fw-b, $green, 27px);
              }
              p {
                @include get-font($f14, $fw-r, $copiedBackground, 21px);
              }
              &__close--btn {
                all: unset;
                cursor: pointer;
              }
            }
          }
        }

        &-action {
          all: unset;
          cursor: pointer;
          margin-top: 12px;
          @include get-font($f14, $fw-sb, $blue, 21px);

          &.filled {
            background-color: $black;
            padding: 8px 12px;
            color: $white;
            line-height: 21px;
            border-radius: 8px;
          }
        }

        &__content {
          .section--list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            max-height: 60vh;
            overflow-y: auto;
            @include getDefaultScrollbar();

            &__item {
              background-color: #f2f2f2;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px 24px 12px 16px;
              border-bottom: 1px solid rgba($color: $lighterGray, $alpha: 0.2);
              cursor: pointer;
              gap: 10px;

              .item {
                &-index {
                  @include get-font($f12, $fw-sb, $black);

                  .dot{
                    display: inline-block;
                    height: 3px;
                    width: 3px;
                    border-radius: 100%;
                    background-color: $black;
                    margin: 0 2px;
                  }
                }
                &-title {
                  @include get-font($f16, $fw-m, $green);
                }
              }
              &--action {
                all: unset;
                cursor: pointer;
              }
            }
          }
          .question--list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            max-height: 60vh;
            overflow-y: auto;
            @include getDefaultScrollbar();

            &__title {
              text-transform: uppercase;
              @include get-font($f14, $fw-b, $copiedBackground);
              padding: 12px 24px 12px 16px;
            }

            &__item {
              display: flex;
              gap: 12px;
              padding: 12px 24px 12px 16px;
              border-bottom: 1px solid $imageWrapperBackgroundGray;
              &--left {
                cursor: pointer;
                margin-top: 4px
              }
              &--right {
                .title {
                  @include get-font($f16, $fw-m, $black);
                }
                .type {
                  @include get-font($f14, $fw-r, $black);
                  opacity: 0.6;
                  margin-top: 5px;
                }
              }
            }
          }
          .selected-questions--list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            &__item {
              padding: 16px 24px 16px 16px;
              border-bottom: 1px solid #EDEDED;
              &--top {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .info {
                  .title {
                    @include get-font($f16, $fw-m, $black);
                  }
                  .type {
                    @include get-font($f14, $fw-r, $black);
                    opacity: 0.6;
                    margin-top: 5px;
                  }
                }
                .action {
                  all: unset;
                  cursor: pointer;
                  color: $removeRed;
                  height: 16px;
                  width: 16px;
                }
              }
            }
            .question--list__item {
              padding: 16px 24px 0 0;
              border: none;

              &--left {
                cursor: pointer;
                margin-top: 0;
              }
              &--right {
                .title {
                  @include get-font($f14, $fw-r, $black);
                }
              }
            }
          }
          .no-questions {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 0;
            text-align: center;
            img{
              margin-bottom: 25px;
            }
            &__text {
              text-align: center;
              @include get-font($f16, $fw-m, $copiedBackground, 24px);
            }
          }
          .add-more-questions {
            &__button {
              all: unset;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding: 16px;
              box-sizing: border-box;
              background-color: #CCECDA;
              cursor: pointer;

              span{
                @include get-font($f16,$fw-m,$green)
              }
            }
          }
        }

        &__footer {
          padding: 24px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 0px 4px rgba(23, 23, 23, 0.1);

          button {
            all: unset;
            padding: 8px 16px;
            cursor: pointer;
            border-radius: 8px;

            &.primary {
              background-color: $green;
              @include get-font($f14, $fw-m, $white, 21px);
            }
            &.danger {
              @include get-font($f14, $fw-m, $removeRed, 21px);
              padding: 0;
            }
            &.disabled {
              opacity: 0.2;
              pointer-events: none;
            }
          }
        }
      }
      &--date{
        &__item{
          margin-top: 8px;
          & + &{
            margin-top: 16px;
          }
          p {
            opacity: .6;
            @include get-font($f12,$fw-b,$copiedBackground);
          }
          &--date-picker{
            outline: none;
            border: none;
            margin-left: 15px;
            @include get-font($f14,$fw-m,$copiedBackground);
            width: 90%;
            cursor: pointer;
            &__container{
              display: flex;
              align-items: center;
              border: 1px solid $lightGrey;
              border-radius: 8px;
              padding: 11px 16px;
              margin-top: 8px;
              position: relative;
              max-width: 256px;
            }
            &__icon{
              cursor: pointer;
              &.clear{
                position: absolute;
                right: 18px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            
          }
        }
      }
    }

    // doing this for demo will remove later
    .pr-manage__filters{
      width: 256px;
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .survey {
    &__reports {
      padding-top: 24px !important;
      &__remove-content {
        padding: 0 !important;
      }
      .collaboration__header__text {
        font-size: $f22;
        font-weight: $fw-sb;
        margin-left: 8px;
        margin-bottom: 8px !important;
      }
      &__section{
        padding: 16px;
        &__header {
          &__action {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          }
        }
        &__responses{
          .survey__reports__header__container{
            margin-left: 0px !important;
          }
          .survey__playable{
            .survey__reports__semiSection{
              margin-left: 0px !important;
            }
          }
        }
      }
      &__header {
        &__action {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          margin-top: 8px;
          &__btn {
            margin-top: 8px;
          }
          .survey__reports__header__multiSubmissionScroller{
            .survey__reports__multiSubmissionScroller__btn{
              margin: 0px;
            }
            .survey__details__submissionCount__text{
              margin: 0px 16px;
            }
          }
        }
      }
      .pr-manage {
        &__sidebar {
          width: 100%;
          &__content-container {
            padding: 0;
            width: 100%;
          }
          &__list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__item {
              flex: 1;
            }
          }
        }
        &__content {
          margin-left: 0;
          padding: 24px 16px;
          &--max-width {
            max-width: 100%;
            margin-left: 0px;
          }
        }
      }
      &__summary {
        &__question {
          padding: 16px 0px 16px 0px !important;
          &__item {
            margin-bottom: 24px;
          }
          &__pill {
            &__data {
              padding: 8px 12px;
            }
            &__percentage {
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              word-break: keep-all;
              .survey__reports__summary__question__pill__chevron {
                width: 24px;
                display: flex;
                align-items: center;
                margin-left: 8px;
              }
            }
          }
        }
        &__media--item{
          max-width: 100%;
        }
      }
    }
  }
}
