.pr-manage {
  &__btn {
    @extend %appearance-none;
    border: 0;
    padding: 0 12px;
    @include get-font($f14, $fw-m, $white,$lh);
    border-radius: 8px;
    background: transparent;
    &--with-margin {
      margin-right: 12px;
    }
    cursor: pointer;
    &--disabled {
      // opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
      background: #EDEDED !important;
      color: #B9B9B9 !important;
      outline: 0px;
      border: 1px solid transparent !important;
    }
    > img {
      display: inline-block;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
  &__assign-btn {
    border: 1px solid $blue;
    color: $blue;
    display: flex;
    align-items: center;
    padding: 8px 16px;
  }
  &__begin-btn {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: $mediumBlue;
    color: $white;
  }
  &__edit-btn {
    height: 34px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include transitionEase(#{background});
    &:hover {
      background: $grayBackground;
    }
    > img {
      margin-right: 12px;
    }
    @include get-font($f12, $fw-b, $black, $lh);
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }
  &__report-btn {
    height: 36px;
    border: 1px solid $blue;
    background: $superLightestBlue;
    @include get-font($f12, $fw-b, $blue, $lh);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px 16px;
    flex-shrink: 0;
    margin-left: 6px;
    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        color: inherit;
        background: inherit;
      }
    }
    &--secondary {
      text-transform: capitalize;
      letter-spacing: normal;
      border: 0;
      font-size: $f14;
      font-weight: $fw-sb;
      line-height: 1;
      > img {
        margin-right: 8px;
      }
    }
  }
  &__reportdownload {
    &__icon {
      margin-right: 6px;
    }
    &.pr-manage__report-btn {
      text-transform: capitalize !important;
    }
  }
  &__document-verify-btn {
    @extend %appearance-none;
    @include get-font($f14, $fw-m, $blue, $lh);
    border: 0;
    background: transparent;
    padding: 0;
    cursor: pointer;
    &--accepted {
      color: $green;
    }
    &--rejected {
      color: $red;
    }
  }
  &__refresh-btn {
    background: #4990e2;
    height: 34px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include transitionEase(#{background});
    > img {
      margin-right: 12px;
      width: 16px;
    }
    @include get-font($f12, $fw-b, $white, $lh);
    letter-spacing: 0.6px;
    text-transform: uppercase;
      &__rotate {
        animation: rotation 2s infinite linear;
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
      }
  }
}

.load-more-btn {
  @extend %appearance-none;
  @include form-input-border-shadow();
  background-color: $white;
  @include get-font($f14, $fw-m, $black);
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
  &__container {
    width: 100%;
    text-align: center;
  }
}
