.collaboration-manage {
  max-width: $maxWidthContainer;
  margin: 64px auto 0;
  .collaboration__reopen {
    border-radius: 10px;
    margin-bottom: 24px;
  }
  &.less-padding {
    padding: 24px 0px !important;
    .collaboration__header{
      >p{
        margin-bottom: 0px;
      }
    }
  }
  &__search {
    &:has(input:focus) {
      border: 1px solid #747474;
    }
    input {
      &:active, &:focus {
        &::placeholder {
          color: $lightestBlack;
       }
      }
    }
    margin-bottom: 24px;
    background: #f2f2f2;
    padding: 12px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 8px;
    > img {
      display: inline-block;
      margin-right: 12px;
    }
    > input {
      @include get-font($f16, $fw-r, $black, $lh);
      border: 0;
      padding: 0;
      flex: 1;
      background: transparent;
      outline: none;
    }
  }
  &__applicant-files {
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > a {
          height: 40px;
          line-height: 40px;
          background: $lightestBlue;
          border-radius: 10px;
          padding: 0 16px;
          align-items: center;
          @include get-font($f16, $fw-m, $blue);
          text-decoration: none;
          max-width: calc(100% - 142px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          > img {
            margin-right: 12px;
          }
        }
        > button {
          @extend %appearance-none;
          height: 36px;
          border: 0;
          padding: 0 16px;
          border-radius: 10px;
          background: $lightestRed;
          @include get-font($f14, $fw-m, $removeRed);
          cursor: pointer;
          > img {
            margin-right: 8px;
          }
        }
      }
    }
  }
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &__public {
      display: unset ;
      &__text {
        margin-left: 15%;
        margin-bottom: 32px !important;
      }
    }
    &__empty {
      width: 100%;
      text-align: center;
      padding: 64px 0;
      &__icon {
        width: 128px;
        margin-bottom: 16px;
      }
      &__title {
        @include get-font($f16, $fw-m, $black, $lh);
        margin-bottom: 8px;
      }
      &__helper {
        @include get-font($f16, $fw-m, $lightBlack, $lh);
      }
    }
    &__links {
      min-width: 264px;
      margin-right: 16px;
      padding: 32px 0 12px;
      &__header {
        margin-left: 16px;
        margin-bottom: 12px;
        @include get-font($f12, $fw-sb, $lighterBlack, $lh);
        text-transform: uppercase;
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        &__item {
          height: 48px;
          padding: 12px 16px;
          box-sizing: border-box;
          cursor: pointer;
          &:hover,
          &:active,
          &--active,
          &:focus {
            background: $grayBackground;
          }
        }
        &__box {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 12px;
          border-radius: 5px;
          vertical-align: middle;
          &--active {
            background: $green;
          }
          &--disabled {
            background: $lightestBlack;
          }
        }
        &__text {
          @include get-font($f14, $fw-sb, $black, $lh);
          vertical-align: middle;
        }
      }
    }
    &__container {
      background: $white;
      @extend %appearance-none;
      @extend %border-box-shadow;
      border-radius: 10px;
      box-sizing: border-box;
    }
    &__data {
      width: calc(100% - 280px);
      padding: 30px 24px;
      &__header {
        @include get-font($f14, $fw-r, $blue, $lh);
        text-decoration: none;
        display: block;
        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
        margin-bottom: 22px;
      }
    }
    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: 16px;
      &__item {
        padding: 12px 0;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__checkbox {
        display: inline-block;
        cursor: pointer;
        width: auto;
        height: auto;
        > input {
          position: absolute;
          opacity: 0;
          pointer-events: none;
        }
        > img {
          width: 24px;
          height: auto;
          margin-right: 16px;
        }
      }
      &__details {
        display: flex;
        align-items: flex-start;
        flex: 1;
        &--container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        }
        &__info {
          display: flex;
          text-decoration: none;
          align-items: center;
          justify-content: flex-start;
          flex: 0.5;
        }
        &__action {
          flex: 0.5;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &--child {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &:first-child {
              margin-right: 16px;
            }
          }
        }
        &__image {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          object-fit: cover;
          margin-right: 16px;
          vertical-align: middle;
        }
        &__text {
          @include get-font($f14, $fw-m, $black, $lh);
          text-decoration: none;
          vertical-align: middle;
          display: inline-block;
          &--helper {
            @include get-font($f12, $fw-r, $lightBlack, $lh);
          }
        }
      }
      &__message {
        margin-top: 5px;
        @include get-font($f14, $fw-r, $black, $lh);
      }
      &__action {
        &-btn {
          @extend %appearance-none;
          height: 32px;
          border-radius: 7px;
          background: $grayBackground;
          padding: 6px 12px;
          box-sizing: border-box;
          margin-right: 16px;
          border: 0;
          cursor: pointer;
          @include transitionEase();
          &:last-child {
            margin-right: 0;
          }
          &--phone {
            display: inline-flex;
            align-items: center;
            @include get-font($f14, $fw-m, $black, $lh);
            text-decoration: none;
            background: rgba($blue, 0.05);
            &:active,
            &:focus,
            &:hover {
              background: rgba($blue, 0.1) !important;
            }
            > img {
              margin-right: 12px;
            }
            border-radius: 22px;
          }
          &--clear {
            background: $white;
            &:active,
            &:focus,
            &:hover {
              background: $grayBackground !important;
            }
            > img {
              width: 18px;
              height: auto;
            }
          }
          &__text {
            @include get-font($f14, $fw-m, $lightBlack, 0);
          }
          &:active,
          &:focus,
          &:hover {
            background: $lightestBlack;
          }
          &--disabled {
            opacity: 0.65;
            cursor: not-allowed;
            &:active,
            &:focus,
            &:hover {
              backgorund: inherit;
            }
          }
          &--primary {
            background: $lightestBlue;
            &:active,
            &:focus,
            &:hover {
              background: $lighterBlue;
            }
            .collaboration-manage__content__list__action-btn__text {
              color: $blue;
            }
          }
        }
      }
    }
  }
  &__sort {
    @include get-font($f14, $fw-r, $black, $lh);
    border: 1px solid $superLightBlack;
    padding: 6px 12px;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    display: block;
    width: 100%;
    max-width: fit-content;
    > span {
      margin-right: 12px;
    }
    > select {
      border: 0;
      background: $white;
      @include get-font($f14, $fw-r, $black, $lh);
      outline: none;
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .collaboration-manage {
    padding-left: 16px !important;
    padding-right: 16px !important;
    &__sort {
      padding: 12px;
    }
    &__content {
      display: block;
      &__links {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
      &__data {
        width: 100%;
        padding: 16px;
      }
      &__list {
        &__details {
          &--container {
            display: block;
          }
          &__action {
            margin-top: 8px;
            flex: 1;
            flex-wrap: wrap;
            justify-content: flex-start;
            &--child {
              &:nth-child(2n) {
                margin-top: 16px;
              }
              justify-content: flex-start;
            }
          }
          &__info {
            justify-content: flex-start;
            flex: 1;
          }
        }
        &__message {
          margin-left: 0;
        }
      }
    }
  }
}
