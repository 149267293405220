.quiz {
  &__playable {
    list-style: none;
    margin: 0;
    padding: 0;
    &__replayIndication {
      background: #ffffff;
      padding: 16px;
      align-items: center;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      &__image {
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }
    }
    &__playTitle {
      @include get-font($f14, $fw-m, $black);
    }
    &__playPadding {
      padding: 0px 0 !important;
    }
    &__playPagePadding {
      padding: 36px 0 !important;
    }
    &__saperator {
      color: $copiedBackground !important;
      margin: 0 8px !important;
    }
    &__footer {
      margin-top: 24px;
      padding: 16px;
      border-top: 1px solid $grayBackground;
      display: flex;
      align-items: center;
      position: fixed;
      bottom: 0;
      background: white;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      &__panel {
        max-width: 760px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
      }
      &__nextBtn {
        background-color: $orange;
        padding: 0;
        border: 0;
        border-radius: 10px;
        @include get-font($f16, $fw-sb, $white, $lh);
        text-align: center;
        width: 15%;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        &:hover {
          background-color: $orange;
        }
        &__img {
          margin-left: 8px;
        }
      }
      &__progressBtn {
        border: none;
        position: relative;
        display: flex;
        background-color: $orange;
        border-radius: 8px;
        @include get-font($f16, $fw-sb, $white, 48px);
        text-align: center;
        width: 120px;
        height: 48px;
        transition: all 0.4s ease;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        &.certificate {
          background-color: $blue;
        }
        @media only screen and (max-width: $onlyMobile) {
          width: 100px !important;
        }
        &__img {
          margin-left: 8px;
        }
        &:not(.active) {
          cursor: pointer;
        }
        > .btn {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          line-height: 50px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          opacity: 1;
        }
        > .progress {
          width: 0%;
          z-index: 5;
          background: rgba(255, 255, 255, 0.32);
          opacity: 0;
          transition: all 0.3s ease;
        }
        &.active {
          pointer-events: none;
          > .progress {
            opacity: 1;
            animation: progress-anim 3s ease 0s;
          }
        }
        &[data-progress-style="fill-back"] {
          > .progress {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }
        }
        @keyframes progress-anim {
          0% {
            width: 0%;
          }
          100% {
            width: 100%;
          }
        }
      }
      &__prevBtn {
        width: 120px;
        padding: 8px 16px;
        height: 48px;
        border: none;
        background: $white;
        border: 1px solid #ededed;
        box-shadow: 0px 1px 2px rgba(23, 23, 23, 0.15);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        &__img {
          padding-right: 8px !important;
        }
        &--disabled {
          background: #e1e1e1;
        }
        &__text {
          @include get-font($f16, $fw-sb, $copiedBackground, $f24);
        }
      }
    }
    &__action {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid $grayBackground;
      &__btn {
        @extend %appearance-none;
        @include transitionEase(#{background-color});
        background-color: $orange;
        padding: 0;
        border: 0;
        border-radius: 10px;
        @include get-font($f16, $fw-sb, $white, $lh);
        text-align: center;
        width: 100%;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        margin-left: 8px;
        &:hover {
          background-color: $mediumOrange;
        }
      }
    }
    &__complete {
      &__watermark {
        position: absolute;
        width: 104px;
        height: 136px;
        right: -2px;
        top: 143.11px;
      }
      &__container {
        background: $white;
        @include form-input-border-shadow();
        padding: 32px;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 80px;
        border-radius: 10px;
      }
      &__img {
        width: 220px;
        height: 220px;
        margin-bottom: 1em;
      }
      &__title {
        @include get-font($f22, $fw-sb, $black, $lh);
        margin-bottom: 8px;
      }
      &__subtitle {
        @include get-font($f14, $fw-m, $copiedBackground, $lh);
        &.draft{
          margin-bottom: 16px
        }
      }
      &__themeText {
        @include get-font($f14, $fw-b, $orange, $lh);
      }
      &__quizTitle {
        @include get-font($f14, $fw-m, $blue, $lh);
        word-wrap: break-word;
      }
      &__questionAnalysis {
        @include get-font($f14, $fw-b, $copiedBackground, $lh);
        margin-top: 24px;
        margin-bottom: 16px;
      }
      &__questionPills {
        display: flex;
        justify-content: space-around;
        &__pillParent {
          display: flex;
        }
        &__subtitle {
          @include get-font($f16, $fw-m, $black, $f24);
          margin-left: 4px;
          margin-bottom: 40px;
        }
        &__totalQuestion {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $black;
          height: 40px;
          width: 84px;
          border: 2px solid #ffffff;
          box-shadow: 0px 6px 10px 3px rgba(23, 23, 23, 0.1);
          border-radius: 100px;
          color: $white;
          @include get-font($f16, $fw-sb, $white, $f24);
        }
        &__correct {
          display: flex;
          align-items: center;
          justify-content: center;
          @include get-font($f16, $fw-sb, $white, $f24);
          background: $green;
          height: 40px;
          width: 84px;
          border: 2px solid #ffffff;
          box-shadow: 0px 6px 10px 3px rgba(23, 23, 23, 0.1);
          border-radius: 100px;
          color: $white;
        }
        &__incorrect {
          display: flex;
          align-items: center;
          justify-content: center;
          @include get-font($f16, $fw-sb, $white, $f24);
          background: $red;
          height: 40px;
          width: 84px;
          border: 2px solid #ffffff;
          box-shadow: 0px 6px 10px 3px rgba(23, 23, 23, 0.05);
          border-radius: 100px;
          color: $white;
        }
      }
      &__actionButtons {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        &.certification{
          justify-content: center
        }
        &__btn {
          @extend %appearance-none;
          @include transitionEase(#{background-color});
          padding: 0;
          border: 0;
          border-radius: 10px;
          @include get-font($f16, $fw-sb, $white, $lh);
          text-align: center;
          width: 100%;
          height: 48px;
          line-height: 48px;
          cursor: pointer;
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          &__icon {
            margin-left: 10px;
          }
          &--share {
            background-color: $blue;
            margin-right: 20px;
            box-shadow: 0px 1px 2px rgba(23, 23, 23, 0.15);
            @media only screen and (max-width: $onlyMobile) {
              margin: 0 !important;
            }
          }
          &--replay {
            background-color: transparent;
            border: 1px solid #f47816;
            box-shadow: 0px 2px 4px rgba(23, 23, 23, 0.1);
            @include get-font($f16, $fw-sb, $orange, $lh);
            margin-right: 20px;
            margin-left: 20px;
            text-decoration: none;
            @media only screen and (max-width: $onlyMobile) {
              margin: 0 !important;
            }
          }
          &--newQuiz {
            background-color: $orange;
            margin-left: 20px;
            text-decoration: none;
            border: 1px solid #f47816;
            box-shadow: 0px 2px 4px rgba(23, 23, 23, 0.1);
            &.certification{
              width: unset;
              height: unset;
              line-height: 24px;
              padding: 12px 16px;
            }
            @media only screen and (max-width: $onlyMobile) {
              margin: 0 5px !important;
            }
          }
        }
      }
      &__heading {
        @include get-font($f18, $fw-sb, $copiedBackground, $lh22);
        margin-bottom: 1em;
      }
      &__scoreContainer {
        box-sizing: border-box;
        padding: 8px 24px;
        width: 200px;
        height: 49px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        box-shadow: 0px 6px 10px 3px rgba(23, 23, 23, 0.1);
        border-radius: 100px;
        @include get-font($f22, $fw-sb, $orange, $lh);
        position: relative;
        left: 35.5%;
        margin-top: 16px;
        margin-bottom: 24px;
        &.certificate {
          color: $blue;
        }
        @media only screen and (max-width: $onlyMobile) {
          width: 50% !important;
          left: auto !important;
        }
      }
    }
    &__item {
      background: $white;
      @include form-input-border-shadow();
      margin-top: 24px;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 24px 16px 12px 16px;
      border: 0.5px solid transparent;
      @include transitionEase();
      &--mandatory {
        border-color: $removeRed;
      }
      &--imageCorrectOption {
        // border-top-width: 2px;
        border-right-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 2px;
        // border-top-style: solid;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        // border-top-color: rgb(0, 161, 70);
        border-right-color: rgb(0, 161, 70);
        border-bottom-color: rgb(0, 161, 70);
        border-left-color: rgb(0, 161, 70);
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
      }
      &--correct {
        border-color: $green;
      }
      &__no-response {
        @include get-font($f14, $fw-r, $black, $lh);
        margin-top: 8px;
      }
      &--dragging {
        box-shadow: 0 6px 10px 3px $superLightBlack;
        border: 0.5px solid $superLightBlack;
      }
      &__draggable {
        width: 100%;
        text-align: center;
        margin-bottom: 0px 0px 16px 16px;
        @media screen and (max-width: $onlyMobile) {
          margin-left: 16px;
        }
      }
      &--addable {
        position: absolute;
        width: 100%;
        min-height: 20px;
        &:hover {
          position: relative;
          margin-top: -24px;
          padding-top: 24px;
          .quiz__form__add-question--inline {
            display: block;
          }
        }
      }
    }
    &__image {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
      &__container {
        width: calc(100% + 32px);
        margin-top: 16px;
        overflow: hidden;
        border: 1px solid $superLightBlack;
        left: -17px;
        position: relative;
        .react-player__video{
          margin-top: unset;
          video{
            border-radius: unset;
          }
        }
      }
    }
    &__title {
      @include get-font($f16, $fw-m, $black, $lh);
      word-break: break-word;
      margin-top: 8px;
      &__reordertext {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 30rem;
        > sup {
          color: $red;
        }
      }
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      > sup {
        color: $red;
      }
    }
    &__description {
      margin-top: 8px;
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      word-break: break-word;
    }
    &__correctOptionContainer {
      height: auto;
      margin: 16px 0px;
    }
    &__select {
      &__text {
        margin-top: 24px;
        &__answerFeedback {
          &__correct {
            background-color: $green;
            border-radius: 24px;
            padding: 0px 8px;
            display: flex;
            @include get-font($f14, $fw-b, $white, $lh);
          }
          &__correctButNotSelected {
            background-color: $quizGreen;
            border-radius: 24px;
            padding: 0px 8px;
            display: flex;
            @include get-font($f14, $fw-b, $white, $lh);
          }
          &__incorrect {
            background-color: $removeRed;
            border-radius: 24px;
            padding: 0px 8px;
            display: flex;
            @include get-font($f14, $fw-b, $white, $lh);
          }
        }
        &__item {
          display: flex;
          align-items: flex-start;
          margin-top: 20px;
          width: 100%;
          cursor: pointer;
          &:first-child {
            margin-top: 0;
          }
        }
        &__image {
          margin-right: 10px;
        }
        &__title {
          &__playPageTitle {
            background: $imageWrapperBackgroundGray;
            padding: 12px 16px;
            border-radius: 100px;
            width: 100%;
            display: flex;
            @include get-font($f14, $fw-m, $black, $lhOnboarding);
            &--correctAnswer {
              background: $green;
              color: $white !important;
              padding: 12px 16px;
              border-radius: 100px;
              width: 100%;
              @include get-font($f14, $fw-m, $black, $lhOnboarding);
            }
            &--wrongAnswer {
              background: $removeRed;
              color: $white !important;
              padding: 12px 16px;
              border-radius: 100px;
              width: 100%;
              @include get-font($f14, $fw-m, $white, $lhOnboarding);
            }
          }
          @include get-font($f16, $fw-r, $black, $lh);
          &__correctIndication {
            @include get-font($f16, $fw-m, $green, $f24);
          }
          &__correctText {
            margin-left: 8px;
            @include get-font($f16, $fw-r, $copiedBackground, $f24);
          }
        }
        .survey__playable__input {
          margin-top: 16px;
        }
      }
      &__image {
        margin-top: 24px;
        .survey__playable__input {
          margin-top: 16px;
        }
        &__answerFeedback {
          &__correct {
            background-color: $green;
            border-radius: 24px;
            padding: 4px 16px;
            display: flex;
            align-items: center;
            width: 75px;
            justify-content: space-around;
            @include get-font($f14, $fw-b, $white, $lh);
          }
          &__correctButNotSelected {
            background-color: $quizGreen;
            border-radius: 24px;
            padding: 4px 16px;
            display: flex;
            align-items: center;
            width: 75px;
            justify-content: space-around;
            @include get-font($f14, $fw-b, $white, $lh);
          }
          &__incorrect {
            background-color: $removeRed;
            border-radius: 24px;
            padding: 4px 16px;
            display: flex;
            align-items: center;
            width: 75px;
            justify-content: space-around;
            @include get-font($f14, $fw-b, $white, $lh);
          }
        }
        &__container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &__box {
          border: 1px solid $superLightBlack;
          border-radius: 10px;
          width: 350px;
          position: relative;
          overflow: hidden;
          &:nth-child(n + 3) {
            margin-top: 16px;
          }
        }
        &__image {
          width: 350px;
          height: 257px;
          object-fit: contain;
          background-color: $grayBackground;
        }
        &__check {
          &__container {
            position: absolute;
            top: 0;
            left: 0;
            padding: 6px;
            background-color: $white;
            &--right {
              left: unset;
              right: 0;
              cursor: pointer;
            }
          }
        }
        &__title {
          padding: 8px 8px 8px 13px;
          @include get-font($f16, $fw-m, $black, $lhOnboarding);
          &--correctAnswer {
            background: $green;
            padding: 12px;
            @include get-font($f16, $fw-m, $white, $lhOnboarding);
            position: inherit;
            width: 100%;
            bottom: 5px;
            height: 100%;
          }
          &--wrongAnswer {
            background: $removeRed;
            color: $white;
            padding: 12px;
            @include get-font($f16, $fw-m, $white, $lhOnboarding);
            position: inherit;
            width: 100%;
            bottom: 5px;
            height: 100%;
          }
          &__correctIndication {
            @include get-font($f16, $fw-m, $green, $f24);
            padding: 0px;
            margin-left: 5px;
          }
          &__correctText {
            margin-left: 5px;
            @include get-font($f16, $fw-m, $copiedBackground, $f24);
          }
        }
      }
    }
    &__copyicon {
      display: flex;
      padding-top: 12px;
      margin-top: 18px;
      position: relative;
      &__img {
        margin-left: auto;
      }
      &::after {
        content: " ";
        position: absolute;
        height: 1.5px;
        background: #ededed;
        width: calc(100% + 32px);
        left: -16px;
        top: 0;
      }
    }
    &__text-answer {
      @include get-font($f14, $fw-r, $black, $lh);
      &--margin {
        margin-top: 20px;
      }
    }
  }
}
@media only screen and (max-width: $onlyMobile) {
  .quiz__details__content{
    padding: 16px;
  }
  .quiz-form-date-picker-mob{
    width: 100%;
    padding: 16px 0px;
  }
  .quiz__form__save__date-picker__icon{
    margin: 0px 16px;
  }
  .quiz__form__save__btn {
    width: 100%;
    padding: 16px 0px;
  }
  .quiz__form__save__btn__title{
    margin-left: 16px;
  }
  .quiz__form__save__btn > img{
margin: 0px 16px;
  }
}
@media only screen and (max-width: 360px) {
  .quiz__form__save__date-picker__icon{
    margin: 0px 12px;
  }
}
