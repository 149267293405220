.collaboration {
  &__view {
    padding: 32px 0;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .user-block {
      margin-bottom: 0;
      margin-right: 16px;
      @media only screen and (max-width: $onlyMobile) {
        margin-right: unset;
      }
    }
    .post-comments-list {
      margin: 0;
    }
    @media only screen and (max-width: $onlyMobile) {
      padding: 0;
    }
    &--with-height {
      min-height: calc(100vh - #{$minHeightForFullView});
      @media only screen and (max-width: $onlyMobile) {
        min-height: auto;
      }
    }
    &__details {
      max-width: $centeredContainer;
      margin: 0 auto;
      width: 100%;
      background: $white;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 $superLightBlack;
      border: solid 0.5px $superLightBlack;
      .slider-div{
        .slick-list{
          overflow: visible;
        }
      }
      &__timing-modal {
        width: 570px;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 16px !important;
        .default-modal__header{
          .default-modal__header__title{
            @include get-font($f18, $fw-sb, $black ,$lh);
          }
        }
        .text {
          @include get-font($f16, $fw-r, $copiedBackground ,$lh24);
        }
        .limit-img{
          display: flex;
          justify-content: center;
          margin-top: 24px;
          align-items: center
       }
        .timer_div{
          display: flex;
          padding: 24px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid  #F60E3A;
          background: #FFECF0;
          margin: auto;
          width: 60%;
          margin-top: 24px;
          &.green{
            background: #CCECDA;
            border: 1px solid $green;
              .min_div{
                .count-text{
                  background-color: $green
                }
              }
          }
          .text {
            @include get-font($f14, $fw-m, $darkBlack, $lhOnboarding );
            text-align: center;
          }
          .count_div{
            display: flex;
            margin-top: 24px;
          }
          .min_div{
            display: flex;
            gap: 4px;
          }
          .dot-text {
            background-color: transparent;
            background: transparent;
            padding: 4px 8px;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 4px;
            @include get-font($f28, $fw-sb, $darkBlack, $lh,);
          }
          .count-text{
            padding: 4px 8px;
            align-items: center;
            justify-content: center;
            display: flex;
            background: #F60E3A;
            border-radius: 4px;
            @include get-font($f28, $fw-sb, $white, $lh,);
          }
          .min-text{
            margin-top: 4px;
            text-align: center;
            @include get-font($f14, $fw-b, $darkBlack, $lhOnboarding);
          }
         
        }
        .review-button{
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          border: transparent;
          background: $yellow;
          @include get-font($f14, $fw-sb, $black,$lhOnboarding);
          cursor: pointer;
          &.disabled{
          background: #FCF1D2;
          color: $copiedBackground;
          }
        }
        .footer-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 36px;
          align-items: center;
        }
      }
      &__alergens_modal {
        width: 525px;
        top: 32%;
        &__footer-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;
        }
        &_alergen-div {
          border: 1px solid #F2F2F2;
          border-radius: 20px;
          padding: 4px 12px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          margin-right: 12px;
          flex-wrap: wrap;
        }
        &_text {
          &__cancel {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #DD2E1F;
            cursor: pointer;
          }
          &__proceed {
            background-color: $black;
            color: $white;
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            border-radius: 4px;
            cursor: pointer;
          }
          &_message {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 8px;
          }
          &_allergen {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            color: $black
          }
        }
      }
      &__draft_text {
        @include get-font($f16, $fw-sb, $lighterGray, $lh24);
      }
      &__header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .user-block__action {
          margin-left: 8px;
        }
      }
      @media only screen and (max-width: $onlyMobile) {
        border-radius: 0;
        margin-bottom: 100px;
      }
      &__container {
        padding: 24px 24px 0 24px;
        @media only screen and (max-width: $onlyMobile) {
          padding: 16px;
        }
        .productreview__ribbon{
          margin-right: -24px;
          position: unset
        }
      }
      &__heading {
        @include get-font($f12, $fw-b, $lightBlack, $lh);
        text-transform: uppercase;
        margin-bottom: 5px;
      }
      &__name {
        @include get-font($f22, $fw-sb, $black, $lh);
        .post-details__link {
          font-size: inherit;
          font-weight: inherit;
        }
        @media only screen and (max-width: $onlyMobile) {
          font-size: $f16;
          font-weight: $fw-m;
        }
      }
      &__location {
        margin-top: 24px;
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        img {
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      &__types {
        display: flex;
        align-items: center;
        @include get-font($f16, $fw-r, $black, $lh);
        &__divider {
          margin: 0 6px;
          display: inline-block;
        }
        margin-top: 4px;
      }
      &__posted {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include get-font($f22, $fw-sb, $black, $lh);
        @media only screen and (max-width: $onlyMobile) {
          display: flex;
          align-items: stretch;
          flex-direction: column;
          margin-top: 16px;
          gap: 12px;
        }
        .show-interest {
          display: flex;
          align-items: center;
          &__button {
            padding: 10px 16px;
            border-radius: 7px;
            box-shadow: 0 2px 4px 0 $superLightBlack;
            outline: 0;
            cursor: pointer;
            &--interested {
              @include get-font($f14, $fw-sb, $white, $lh);
              text-transform: uppercase;
            }
            &--show-interest {
              @include get-font($f14, $fw-sb, $white, $lh);
              text-transform: uppercase;
              background: $blue;
            }
          }
        }
      }
      &__description {
        margin-top: 24px;
        @include get-font($f16, $fw-r, $black, $lh);
        word-break: break-word;
        white-space: pre-line;
        @media only screen and (max-width: $onlyMobile) {
          margin-top: 16px;
          font-size: $f14;
        }
        .linkified {
          font-size: inherit;
          color: $blue;
          text-decoration: none;
        }
      }
      &__downloads {
        margin-top: 24px;
        &__link {
          height: 44px;
          display: inline-block;
          vertical-align: top;
          border-radius: 10px;
          background: $collaborationBlue;
          padding: 12px 10px;
          box-sizing: border-box;
        }
        &__text {
          @include get-font($f14, $fw-m, $blue, $lh);
          vertical-align: middle;
          display: inline-block;
        }
        &__icon {
          width: 20px;
          height: auto;
          vertical-align: middle;
          display: inline-block;
          margin-right: 8px;
        }
      }
      &__images {
        width: 100%;
        margin-top: 32px;
        display: flex;
        @media only screen and (max-width: $onlyMobile) {
          flex-wrap: wrap;
          gap: 12px;
        }
      }
      &__image {
        width: 136px;
        height: 136px;
        border-radius: 10px;
        border: solid 1px $superLightBlack;
        margin-right: 8px;
        object-fit: cover;
        object-position: top;
        @media only screen and (max-width: $onlyMobile) {
          margin-bottom: 0px;
          width: 120px;
          height: 100px;
          margin-right: 0px;
          min-width: 109px
        }
      }
      &__video-container {
        margin-top: 24px;
        border-radius: 10px;
        background: $black;
        overflow: hidden;
        @media only screen and (max-width: $onlyMobile) {
          margin-top: 16px;
        }
      }
      &__comments {
        .post-comments-containers {
          max-width: 100%;
        }
      }
      &__information {
        &__container {
          display: flex;
          margin-top: 24px;
          @media only screen and (max-width: $onlyMobile) {
            margin-top: 0;
            display: block;
          }
        }
        flex: 1;
        @media only screen and (max-width: $onlyMobile) {
          width: 100%;
          margin-top: 16px;
        }
        &__heading {
          @include get-font($f12, $fw-b, $lightBlack, $lh);
          text-transform: uppercase;
          letter-spacing: 0.6px;
        }
        &__details {
          display: flex;
          align-items: center;
          margin-top: 6px;
          min-height: 48px;
          flex-wrap: wrap;
        }
        &__icon {
          display: inline-block;
          width: 48px;
          height: 48px;
          border-radius: 6px;
          border: 1px solid $grayBackground;
          object-fit: contain;
          margin-right: 16px;
          @media only screen and (max-width: $onlyMobile) {
            width: 36px;
            height: 36px;
          }
          &--multi {
            width: 32px;
            height: 32px;
            object-fit: contain;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }
        &__text {
          @include get-font($f16, $fw-m, $black, $lh);
          display: inline-block;
          @media only screen and (max-width: $onlyMobile) {
            font-size: $f14;
          }
          &--top-margin {
            margin-top: 12px;
            font-weight: $fw-r;
          }
          &--multi {
            display: flex;
            align-items: center;
            margin-right: 24px;
          }
        }
      }
      &__creator {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-top: 24px;
        padding-bottom: 8px;
        border-bottom: 1px solid $grayBackground;
        a {
          color: inherit;
          font-size: inherit;
          text-decoration: none;
        }
      }
      &__action {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        &.interested-btn{
          background-color: #F2F2F2;
          padding: 8px 16px;
          border-radius: 8px;
          height: fit-content;
          justify-content: center;
          >span{
            @include get-font($f14, $fw-m, $lightBlack, $lh);
            letter-spacing: normal;
          }
        }
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        padding-top: 4px;
        &__item {
          margin-top: 8px;
          @include get-font($f16, $fw-r, $black, $lh);
          @media only screen and (max-width: $onlyMobile) {
            font-size: $f14;
          }
        }
      }
    }
    &__action {
      &__donate {
        display: flex;
        align-items: center;
        margin-right: 16px;
        border-radius: 8px;
        border: 0.5px solid rgba(0, 0, 0, 0.10);
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(23, 23, 23, 0.15);
        @include get-font($f14, $fw-m, "", $lhOnboarding);
        padding: 8px 16px;
        cursor: pointer;
      }
      &__document {
        margin-left: 0;
        margin-right: 16px;
        cursor: pointer;
      }
      &__link {
        &--interest {
          color: $white;
        }
        &--green {
          color: $green !important;
        }
        &--interested {
          @include get-font($f12, $fw-b, $blue, $lh);
          letter-spacing: 0.6px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-right: 8px;
            width: 12px;
          }
        }
        &--show-interest {
          height: 40px;
          border: 0.5px solid $superLightBlack;
          border-radius: 6px;
          background: $blue;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 16px;
          box-sizing: border-box;
          text-align: center;
          @include get-font($f14, $fw-b, $white, $lh);
          cursor: pointer;
          min-width: 156px;
          @media only screen and (max-width: $onlyMobile) {
            display: flex;
            width: 100%;
          }
          &--disabled {
            opacity: 0.65;
            cursor: not-allowed;
          }
        }
        &--product {
          background: $yellow !important;
          color: $black !important;
        }
      }
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__cancel-btn {
        @extend %appearance-none;
        border: 0;
        background: none;
        @include get-font($f14, $fw-m, $lightBlack, $lh);
        cursor: pointer;
        margin-right: 24px;
        @include transitionEase(#{color});
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        &:hover,
        &:focus,
        &:active {
          color: $mediumBlack;
        }
        img {
          margin-right: 8px;
          display: inline-block;
        }
      }
      &__btn {
        @extend %appearance-none;
        @extend %border-box-shadow;
        border-radius: 7px;
        height: 40px;
        background: $white;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        @include get-font($f14, $fw-b, $black, $lh);
        letter-spacing: 0.7px;
        cursor: pointer;
        text-decoration: none;
        flex-shrink: 0;
        img {
          margin-right: 8px;
          width: 16px;
        }
        &--disabled {
          cursor: not-allowed;
          opacity: 0.65;
        }
        &--yellow {
          background: $yellow;
          @include transitionEase(#{background});
          &:hover,
          &:active,
          &:focus {
            background: $darkYellow;
          }
        }
        &--green {
          background: $green;
          color: $white;
          @include transitionEase(#{background});
          &:hover,
          &:active,
          &:focus {
            background: $darkGreen;
          }
        }
      }
    }
    &__response-switch {
      margin-left: 24px;
      padding-left: 24px;
      border-left: 1px solid $black;
      height: 26px;
      display: flex;
      align-items: center;
      &__title {
        @include get-font($f14, $fw-m, $black, $lh);
        margin-right: 16px;
      }
    }
  }
  &__admin-note {
    border: 1px solid $superLightBlack;
    border-left: 0;
    border-right: 0;
    padding: 16px 0;
    margin-top: 24px;
    &__title {
      @include get-font($f12, $fw-sb, $orange, $lh);
      letter-spacing: 0.6px;
      margin-bottom: 8px;
    }
    &__details {
      @include get-font($f14, $fw-r, $black, $lh);
    }
  }
  &__reopen {
    background: $removeRed;
    min-height: 66px;
    width: 100%;
    display: flex;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 24px;
    &--closed {
      background: $lighterBlack;
    }
    &--pink {
      background: $lightestPink;
      .collaboration__reopen__title {
        color: $removeRed;
      }
    }
    &--disabled {
      background: $lighterYellow;
      .collaboration__reopen__title {
        color: $black;
      }
    }
    &__title {
      @include get-font($f14, $fw-m, $white, $lh);
      margin-right: 16px;
    }
  }
  &__document {
    &__file {
      display: inline-flex;
      padding: 0 16px;
      height: 40px;
      max-width: 100%;
      box-sizing: border-box;
      > p {
        @include get-font($f16, $fw-m, $blue);
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      background: $grayBackground;
      border-radius: 10px;
      align-items: center;
      > button {
        @extend %appearance-none;
        border: 0;
        background: transparent;
        padding: 0;
        margin-left: 12px;
        margin-top: 4px;
        cursor: pointer;
        > img {
          width: 12px;
          height: auto;
        }
      }
      &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        > li {
          margin-top: 16px;
        }
      }
    }
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
      margin-top: 24px;
      > img {
        margin-right: 12px;
        vertical-align: middle;
      }
    }
    &__form {
      &--disabled {
        opacity: 0.65;
        cursor: not-allowed;
      }
      position: relative;
      margin-top: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &__upload-btn {
        @include get-font($f16, $fw-sb, $black, $lh);
        padding: 12px 16px;
        display: inline-block;
        background: $grayBackground;
        border-radius: 10px;
      }
      > input {
        position: absolute;
        opacity: 0;
      }
      &__helper {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-left: 12px;
      }
    }
  }
}

.collaboration-reopen-modal {
    width: 100%;
    max-width: 520px;
  >div:first-child{
    padding: 0px 0px 16px 0px !important;
  }
  .default-modal__header {
    margin-bottom: 12px;
  }
  &__title {
    @include get-font($f14, $fw-r, $lightBlack, $lh);
  }
  &__form {
    margin-top: 16px;
    &__title {
      @include get-font($f14, $fw-m, $black, $lh);
      margin-bottom: 12px;
    }
  }
  .default-modal__content {
    padding-bottom: 34px;
  }
  .default-modal__footer {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-bottom: -24px;
    box-sizing: border-box;
    padding: 16px 24px;
    @extend %border-box-shadow;
  }
  .scrollable-modal__footer{
    height: 64px;
    .scrollable-modal__footer__action-btn{
      background-color: $blue;
      border-radius: 7px;
      border: 0;
      height: 32px;
      padding: 6px 12px;
      box-sizing: border-box;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.survey{
        background-color: #00a146;
      }
      &.quiz{
        background-color: $orange;
      }
    }
  }
}

.public-questionnaire{
  &__container{
    min-height: calc(100vh - 92px);
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  &__content{
    .otp-form{
      width: fit-content;
      max-width: 500px;
      background-color: $white;
      padding: 24px;
      border-radius: 8px;
      box-shadow: 0 3px 14px rgba($color: $black, $alpha: .14);

      .login-form__form{
        width: 70%;
        margin: auto;
        margin-top: 32px;
        .otp-form__otp{
          border: 1px solid rgba($color: $black, $alpha: .1);
          &:focus,
          &:active{
            border: 1px solid rgba($color: $black, $alpha: 1);
          }
        }
        .login-form__action__btn:disabled{
          opacity: .2;
        }
      }
      
    }
    .preview{
      &__content{
        padding: 36px;
        text-align: center;
        max-width: 991px;
  
        &--title{
          @include get-font($f24,$fw-sb,$black,36px);
          margin: 30px 0 12px 0;
          
          span{
            color: $red;
          }
        }
        &--description{
          @include get-font($f14,$fw-r,$black);
        }
  
        .info{
          padding: 24px;
          background-color: $white;
          margin: 40px 0;
          border-radius: 10px;
  
          &--title{
            @include get-font($f22,$fw-sb,$black,36px);
            margin: 0 0 12px 0;
          } 
        }
        .action{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 40px;
  
          .action__btn{
            @include get-font($f14,$fw-m,$white);
            border: none;
            outline: none;
            background-color: $blue;
            cursor: pointer;
            border-radius: 8px;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            min-height: 40px;
  
            &.view{
              color: $black;
             background-color: $yellow; 
            }
          }
        }
      }
    }
  } 
}

@media only screen and (max-width: $onlyMobile) {
  .collaboration {
    &__view {
      &__action {
        &__btn {
          flex: 1 auto;
          margin-top: 32px;
          flex-shrink: unset;
        }
      }
      &__response-switch {
        margin-left: 0px;
        padding-left: 0;
        border-left: 0;
        &__title {
          margin-right: 9px;
          font-weight: $fw-r;
        }
      }
      &__details {
        .slick-slider{
          .slick-list{
            overflow: visible;
          }
        }
        &__action {
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: baseline;
          // gap: 16px;
        }
        &__alergens_modal{
          &.default-modal__container{
            width: 100%;
          }
        }
        &__timing-modal{
          &.default-modal__container{
            width: 100%;
            left: 50%;
          }
        }
      }
    }
    &__reopen {
      flex-direction: column;
      border-radius: 0;
      align-items: stretch;

      .collaboration__view__action__btn {
        margin-top: 16px !important;
        flex: auto;
      }
    }
  }
  .collaboration__view__action__container {
    margin-top: 16px;
    .collaboration__view__action__btn {
      margin-top: 0;
    }
  }
}


.preview__otp-form{
  &__modal{
    .default-modal{
      &__header{
      justify-content: center;
        &__title{
          font-size: 22px;
          font-weight: 600;
          margin-left: auto;
        }
        &__close{
          margin-left: auto;
        }
      }
      &__container{
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .otp-form{
      &__errors{
        margin-top: 0;
        p{margin-top: 12px;}
      }

      .login-form__form{
        width: 70%;
        margin: 0 auto;
      }

      .login-form__action__btn:disabled{
        opacity: .2;
      }
    }
    
  }
}


@media only screen and (max-width: $smallMobile) {
  .collaboration__view__details__header{
    .user-block{
      &__action{
          &__container{
             right: 24px;
              &__list{
                  width: 182px;
              }
          }
      }
  }
  }
  .collaboration__view__action__donate{
    flex: 1;
    justify-content: center;
  }
}

