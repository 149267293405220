.collaboration-form {
    max-width: $centeredContainer;
    margin: 0 auto;
    width: 100%;
    @media only screen and (max-width: $onlyMobile) {
        padding: 32px 16px !important;
        box-sizing: border-box;
    }
    &__error {
        @include get-font($f12, $fw-r, $removeRed, $lh);
        margin-top: 6px;
    }
    &__helper {
        @include get-font($f16, $fw-r, $lightBlack, $lh);
        @media only screen and (max-width: $onlyMobile) {
            font-size: $f14;
        }
    }
    &__loader {
        position: absolute;
        background-color: white;
        z-index: 1;
        top: 45%;
        left: 40%;
        width: 14%;
        padding: 8px;
    }
    &__header {
        &__text {
            @include get-font($f32, $fw-b, $black);
            margin-bottom: 8px;
            @media only screen and (max-width: $onlyMobile) {
                font-size: $f24;
            }
        }
        margin-bottom: 32px;
    }
    &__user-select {
        margin-bottom: 24px;
    }
    &__group {
        margin-bottom: 48px;
        position: relative;
        @media only screen and (max-width: $onlyMobile) {
            margin-bottom: 32px;
        }
        .collaboration-form__product_select{
            min-width: 207px;
            margin-top: 4px;
            width: auto;
            &.disabled {
                cursor: not-allowed;
                pointer-events: none;
            }
            @media only screen and (max-width: $onlyMobile) {
                width: 100%;
               }
        }
        &__red-astrick {
            color: $removeRed;
        }
        &--multiple {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: $onlyMobile) {
                flex-direction: column;
                align-items: normal;
                gap: 20px;
            }
        }
        &--last {
            margin-bottom: 16px;
        }
        &--no-margin {
            margin: 0 !important;
        }
        &__label {
            display: block;
            @include get-font($f14, $fw-m, $black, $lh);
            margin-bottom: 12px;
            &--multi {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &--helper {
                @include get-font($f14, $fw-r, $lightBlack, $lh);
            }
            &--with-subtitle {
                margin-bottom: 2px !important;
            }
            &--secondary {
                @include get-font($f12, $fw-b, $black, $lh);
                letter-spacing: 0.6px;
                margin-top: 24px
            }
            &--error {
                @include get-font($f12, $fw-r, $red);
                margin-bottom: 12px;
            }
            &--red {
                color: $red !important;
            }
            &__span {
                margin-left: 8px;
                @include get-font($f12, $fw-r, $lightBlack);
            }
        }
        &__helper {
            @include get-font($f14, $fw-r, $mediumBlack, $lh);
            margin-bottom: 12px;
        }
        &__multi {
            flex: 1;
            margin-right: 24px;
            @media only screen and (max-width: $onlyMobile) {
                margin-right: 0px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__outlets {
        padding: 24px;
        box-sizing: border-box;
        margin-bottom: 16px;;
        &--disabled {
            opacity: 0.65;
        }
        &__container {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        &__remove-btn {
            @extend %appearance-none;
            border: 0;
            padding: 0;
            background: $white;
            cursor: pointer;
        }
        &__create-btn {
            @extend %appearance-none;
            margin-left: 0 !important;
            @include get-font($f16, $fw-sb, $black, $lh);
            height: auto !important;
            cursor: pointer;
        }
        &__city {
            &__container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &__select {
                &__control {
                    @extend %appearance-none;
                    min-width: 215px;
                    height: 48px;
                    border-radius: 10px !important;
                    background-color: $grayBackground !important;
                    border: 0 !important;
                    &:hover, &--is-focused, &--menu-is-open {
                        border: 0 !important;
                        border-color: transparent;
                        box-shadow: none !important;
                    }
                }
                &__text {
                    display: inline-flex;
                    align-items: center;
                    padding-left: 16px;
                    box-sizing: border-box;
                }
                &__value-container {
                    padding-left: 16px !important;
                }
            }
        }
        &__outlet {
            margin-top: 16px;
            align-items: center;
            justify-content: space-between;
            position: relative;
            display: flex;
            &:hover {
                .switch {
                    opacity: 1;
                    @include transitionEase(#{opacity});
                }
            }
            .switch {
                opacity: 0;
            }
            &__create-btn {
                @extend %appearance-none;
                border: 0;
                background: $white;
                margin-top: 4px;
                @include get-font($f16, $fw-m, $mediumBlue, $lh);
                padding: 0;
                cursor: pointer;
                @include transitionEase(#{color});
                &:hover, &:active, &:focus {
                    color: $blue;
                }
            }
            &__list {
                list-style: none;
                margin: 0;
                padding: 0;
            }
            &__input {
                @extend %appearance-none;
                width: 100%;
                max-width: 85%;
                background: $grayBackground;
                padding: 12px 16px;
                border-radius: 10px;
                border: 0;
                resize: none;
                @include get-font($f16, $fw-r, $black, $lh);
                &--disabled {
                    opacity: 0.5;
                }
            }
        }
    }
    &__methodology {
        &__details {
            margin-left: 16px;
        }
        &__title {
            @include get-font($f16, $fw-m, $black, $lh);
            margin-bottom: 4px;
            word-wrap: break-word;
        }
        &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            > li {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &__item {
                display: block;
                margin-bottom: 16px;
                padding: 16px 24px;
                @extend %appearance-none;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
    &__select-multi {
      
        &__control {
            border-radius: 10px !important;
            min-height: 50px !important;
            padding: 2px 16px;
            border: none !important;
            color: #757575;
            @extend %border-box-shadow;
            &--is-focused {
                box-shadow: none !important;
                border: 1px solid $mediumBlack !important;
            }
        }
        &__option {
            &--is-selected {
                background-color: $white !important;
                color: $black !important;
            }
            color: $black !important;
            font-size: $f16 !important;
            display: flex !important;
            align-items: center !important;
            > img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
            }
        }
        &__dropdown-indicator{
            width: 23px !important;
        }
        &__value-container {
            padding-left: 0px !important;
        }
        &__multi-value {
            font-size: $f16;
            background-color: $grayBackground !important;
            padding: 6px !important;
            border-radius: 10px !important;
            &__label {
                font-size: 100% !important;
            }
            &__remove {
                > img {
                    width: 12px;
                    height: auto;
                }
                &:hover {
                    background-color: transparent !important;
                }
            }
        }
    }
    &__brand-name {
        display: flex;
        align-items: center;
        .brand-visibility {
            margin-left: 8%;
            .label{
                @include get-font($f12, $fw-r, $lightestGray, $lh)
            }
            .select{
                border: 0;
                background: transparent;
                outline: 0;
                @include get-font($f12, $fw-r, $lightestGray, $lh)
            }
        }
    }
    .select-paginate{
        &__control{
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
            &--is-focused{
                border: 1px solid $black !important;
            }
        }
        &__indicator{
            padding: 0px;
            padding-left: 12px;
        }
        &__single-value{
            width: 100% !important;
            white-space: normal;
            .value{
                display: flex;
                align-items: center;
                    .image__wrapper{
                        height: 40px;
                        min-width: 40px;
                        >img{
                            height: 100%;
                            width: 100%;
                            border-radius: 8px;
                            border: 1px solid $imageWrapperBackgroundGray ; 
                        }
                    }
                    .title{
                        margin-left: 14px;
                        @include get-font($f16, $fw-r, $black, $lh);
                    }
            }
        }
        &__option-list{
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 8px;
            .image{
                height: 40px; width: 40px;
                border-radius: 8px;
                border: 1px solid $imageWrapperBackgroundGray ;
            }
            .label{
                max-width: 90%;
                white-space: "nowrap";
                text-overflow: 'ellipsis';
                overflow: 'hidden';
                margin-left: 12px;
                @include get-font($f16, $fw-r, $black, $lh);
            }
        }
        &__value-container{
            padding: 2px 0px;
        }
        &__indicator-separator{
            margin-left: 12px
        }
    }
  
    &__select {
        @extend %appearance-none;
        @include get-font($f16, $fw-r, $black, $lh);
        @extend %border-box-shadow;
        width: 100%;
        border-radius: 10px;
        border: 0;
        background: $white;
        color: #757575;
        padding: 12px 16px;
        box-sizing: border-box;
        background-image: url('assets/images/chevrons/icon_chevron_big.svg');
        background-repeat: no-repeat;
        background-position: 96%;
        background-size: 20px;
        &:focus, &:active {
            border: 1px solid $mediumBlack;
        }
    }
    &__title-input {
        @extend %appearance-none;
        @include get-font($f24, $fw-sb, $black, $lh);
        height: 36px;
        width: 100%;
        border: 0;
        box-shadow: none;
        background: transparent;
        resize: none;
        @media only screen and (max-width: $onlyMobile) {
            font-size: $f16;
        }
    }
    &__react-select {
        &__dropdown-indicator{
        background-image: url('assets/images/chevrons/icon_chevron_big.svg') !important;
        }
        &__placeholder {
            color: #757575 !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5
        }
        &__control {
            @extend %border-box-shadow;
            border-radius: 10px !important;
            padding: 8px 4px;
            border-width: 0 !important;
            &--is-focused {
                @extend %border-box-shadow;
                border-width: 1px !important;
                border-color: $mediumBlack !important;
                box-shadow: none !important;
            }
        }
        &__multi-value {
            &__label {
                font-size: $f16 !important;
                font-weight: $fw-r !important;
                color: $black !important;
            }
            &__remove {
                &:hover, &:active, &:focus {
                    background: $grayBackground !important;
                    cursor: pointer;
                }
                color: $black !important;
                font-size: $f16;
            }
        }
    }
    &__input-container {
        width: 100%;
        background: $white;
        @extend %border-box-shadow;
        border-radius: 10px;
        padding: 12px 16px;
        &--media {
            padding: 32px 24px;
            box-sizing: border-box;

            @media screen and (max-width: $onlyMobile){
                padding: 16px;  
            }
        }
        &__media-group {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
            .collaboration-form__group__label {
                display: flex;
                align-items: center;
                @media only screen and (max-width: $onlyMobile) {
                    align-items: flex-start;
                }
                &__icon {
                    width: 20px;
                    height: auto;
                    margin-right: 12px;
                }
            }
            &__input {
                width: 100%;
                padding: 12px 16px;
                box-sizing: border-box;
                background: $backgroundGray;
                border-radius: 10px;
                border: 1px solid transparent;
                ::placeholder {
                    color: #747474;
                    opacity: 0.5; 
                  }
                  :-ms-input-placeholder { 
                   color: #747474;
                   opacity: 0.5;
                  }
                  ::-ms-input-placeholder { 
                   color: #747474;
                   opacity: 0.5;
                  }
                @include get-font($f16, $fw-r, $black, $lh);
                outline: none;
                @media only screen and (max-width: $onlyMobile) {
                    padding: 10px 8px;
                    font-size: $f14;
                }
                &:focus {
                    border: 1px solid $mediumBlack;
                }
                &--error {
                    border-color: $removeRed;
                }
                &.disabled{
                    cursor: not-allowed;
                }
            }
            &__document {
                &__container {
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: $onlyMobile) {
                        display: block;
                    }
                }
                &__remove {
                    vertical-align: top;
                    margin-top: 18px;
                    margin-left: 18px;
                    height: 12px;
                    width: auto;
                }
                &__btn {
                    height: 48px;
                    display: inline-block;
                    padding: 0 24px;
                    background: $backgroundGray;
                    position: relative;
                    border-radius: 10px;
                    line-height: 48px;
                    @include get-font($f16, $fw-sb, $black);
                    margin-right: 12px;
                    cursor: pointer;
                    &__text {
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        color: $black;
                        text-decoration: none;
                        white-space: nowrap;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    &__input {
                        position: absolute;
                        opacity: 0;
                        display: none;
                    }
                }
                &__helper {
                    @include get-font($f14, $fw-r, $lightBlack, $lh);
                    @media only screen and (max-width: $onlyMobile) {
                        font-size: $f12;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
    &__city-input {
        @include get-font($f16, $fw-r, $black, $lh);
        outline: none;
        width: 100%;
        padding: 12px 16px 12px 48px;
        border-radius: 10px;
        @extend %border-box-shadow;
        background: url('assets/images/collaborations/form/pin-2.svg');
        background-repeat: no-repeat;
        background-position: 16px;
        background-size: 20px;
        box-sizing: border-box;
        background-color: $white;
        &:focus, &:active {
            border: 1px solid $mediumBlack;
        }
    }
    &__input {
        @include get-font($f16, $fw-r, $black, $lh);
        outline: none;
        width: 100%;
        padding: 12px 16px;
        border-radius: 10px;
        @extend %border-box-shadow;
        box-sizing: border-box;
        background-color: $white;
        &:focus, &:active {
            border: 1px solid $mediumBlack;
        }
    }
    &__textarea-title {
        resize: none !important;
        @include get-font($f16, $fw-r, $black, $lh);
        @include getDefaultScrollbar();
        outline: none;
        box-sizing: border-box;
        max-height: 168px;
        &::-webkit-scrollbar {
            width: 0;
        }
        &:focus, &:active {
            outline: 1px solid $mediumBlack;
        @include getDefaultScrollbar();
        }
    }
    &__textarea {
        resize: none !important;
        @include get-font($f16, $fw-r, $black, $lh);
        outline: none;
        min-height: 96px;
        box-sizing: border-box;
        max-height: 288px;
        &:focus, &:active {
            border: 1px solid $mediumBlack;
        }
    }
    &__duration-list {
        margin: 0;
        padding: 0;
        list-style: none;
        &__item {
            display: inline-block;
            width: auto;
            vertical-align: top;
            margin-right: 16px;
            @media only screen and (max-width: $onlyMobile) {
                display: block;
                width: 100%;
                margin-right: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &__text {
                @include get-font($f16, $fw-r, $lightBlack, $lh);
                @media only screen and (max-width: $onlyMobile) {
                    font-size: $f14;
                }
                &--selected {
                    font-weight: $fw-sb;
                    color: $black;
                    @media only screen and (max-width: $onlyMobile) {
                        font-weight: $fw-m;
                    }
                }
            }
            .collaboration-form__input-container {
                display: block;
                width: auto;
                cursor: pointer;
                padding: 12px 24px;
                box-sizing: border-box;
                @media only screen and (max-width: $onlyMobile) {
                    margin-bottom: 8px;
                    padding: 10px 12px;
                }
            }
            &__icon {
                vertical-align: middle;
                margin-right: 12px;
                @media only screen and (max-width: $onlyMobile) {
                    width: 16px;
                    margin-right: 8px;
                }
            }
            &__radio {
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }
        }
    }
    &__images-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 16px;
        &.updated{
            display: block;
            .collaboration-form__image-container{
                width: fit-content;
                height: 100%;
                margin-right: 12px;

                &__image{
                    display: block;
                    width: 190px;
                    height: 100%;
                    aspect-ratio: 16/9;
                    object-fit: contain;
                }
                &__remove{
                    transform: none;
                    top: 8px;
                    right: 8px;
                }
            }
            .collaboration-form__image-container--helper{
                height: auto;
                margin: 20px 0 ;
                background: transparent;
                width: 100%;
                justify-content: flex-start;
                cursor: pointer;
                &.loading{
                    background: $black;
                    min-width: 192px;
                    width: 192px;
                    height: 108px;
                    margin: 0;
                    margin-right: 16px;
                }
                &.disabled{
                    cursor: not-allowed;
                    .input-box{
                        opacity: .4;
                    }
                }
                &__content{
                    border-radius: 46px;
                    border: 1px solid $lightGrey;

                    .input-box{
                        padding: 16px 20px;
                    }
                }
            }
            .images-container{
                display: flex;
                align-items: center;
                @include getDefaultScrollbar();
                overflow-x: auto;
            }
        }
    }
    &__image-container {
        width: 120px;
        height: 120px;
        border-radius: 10px;
        border: 1px solid $grayBackground;
        position: relative;
        @include transitionEase(#{transform});
        @media only screen and (max-width: $onlyMobile) {
            width: 60px;
            height: 60px;
        }
        &--helper {
            background: $grayBackground;
            border-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: $onlyMobile) {
                img {
                    width: 25px
                }
            }
            &.loading{
                background:$black;
                .loader__container{
                    padding: 2px 15px;
                }
            }
            &__content{
                .input-box{
                    display: flex;
                    align-items: center;
                    padding: 16px;
                    background-color: $white;
                    justify-content: center;
                    gap: 10px;
                    border-radius: 50px;
                    margin: auto;
                    width: fit-content;
                }
                img{
                    width: 24px;
                    height: 24px;
                }
                span{
                    @include get-font($f14, $fw-m, $copiedBackground, $lh);
                }
                p{
                    @include get-font($f12, $fw-m, $copiedBackground, $lh);
                }
            }
        }
        &__file-input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }
        // margin-right: 16px;
        &__remove {
            @extend %appearance-none;
            background: $removeRed;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            position: absolute;
            top: 15%;
            right: 0;
            transform: translate(-50%, -50%);
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        &__image {
            width: 120px;
            height: 120px;
            border-radius: 10px;
            object-fit: cover;
            object-position: top;
            @media only screen and (max-width: $onlyMobile) {
                width: 60px;
                height: 60px;
            }
        }
    }
    &__video-container{
        position: relative;
        max-width: 350px;
        height: 190px;
        border-radius: 8px;
        &__remove {
            @extend %appearance-none;
            background: $removeRed;
            height: 24px;
            width: 24px;
            border-radius: 24px;
            position: absolute;
            top: 10%;
            right: 0;
            transform: translate(-50%, -50%);
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 1;
        }

        &__file-input{
           display: none;
        }
        &__item{
            position: relative;
            height: 100%;
            background-color: #ededed;
            &.loading{
                background-color: $black;
            }
            border-radius: inherit;
        }
        &--helper{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ededed;
            cursor: pointer;
            &.disabled{
                cursor: not-allowed;
            }
            &.loading{
                background-color: $black;
            }

            &__content{
                .input-box{
                    display: flex;
                    align-items: center;
                    padding: 16px 24px;
                    background-color: $white;
                    justify-content: center;
                    gap: 10px;
                    border-radius: 50px;
                    margin: auto;
                    width: fit-content;
                }
                img{
                    width: 24px;
                    height: 24px;
                }
                span{
                    @include get-font($f14, $fw-m, $copiedBackground, $lh);
                }
                p{
                    margin-top: 8px;
                    @include get-font($f12, $fw-m, $copiedBackground, $lh);
                }
            }
        }
    }
    &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 48px;
        padding-top: 32px;
        border-top: 1px solid $grayBackground;
        @media only screen and (max-width: $onlyMobile) {
            margin-top: 24px;
        }
        &__back {
            @include get-font($f16, $fw-r, $lightBlack, $lh);
            text-decoration: none;
            img {
                vertical-align: middle;
                display: inline-block;
                height: 16px;
                width: auto;
                margin-right: 6px;
                transform: rotate(90deg);
            }
        &__img {
            vertical-align: middle;
            display: inline-block;
            height: 16px;
            width: auto;
            margin-right: 6px;
            transform: rotate(90deg);
        }    
        }
        &__btn {
            @extend %appearance-none;
            height: 40px;
            border-radius: 10px;
            background: $blue;
            padding: 8px 16px;
            border: 1px solid transparent;
            box-sizing: border-box;
            cursor: pointer;
            &--disabled {
                background: $lightBlue;
            }
            &__text {
                @include get-font($f16, $fw-sb, $white, $lh);
            }
            &--danger {
                border: 1px solid $removeRed;
                background: transparent;
                .collaboration-form__action__btn__text {
                    color: $removeRed;
                    font-weight: normal;
                }
                img {
                    transform: rotate(90deg);
                }
            }
        }
    }
    &__suggestion {
        padding: 8px;
        @include get-font($f16, $fw-r, $black, $lh);
        &__container {
            background: $white;
            @extend %border-box-shadow;
            border-radius: 5px;
            position: absolute;
            max-width: 100%;
            margin-top: 10px;
            border: 1px solid $superLightBlack !important;
            overflow: hidden;
            z-index: 10;
            box-sizing: border-box;
        }
    }
    position: relative;
    &__submit {
        margin-top: 32px;
        border-top: 1px solid $grayBackground;
        padding-top: 32px;
        &--single {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        &--multi {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: $onlyMobile) {
                gap: 28px;
                flex-direction: column;
                width: 100%;
                align-items: stretch;
            }
        }
        &__btn-big {
            &__img {
                transform: rotate(270deg)
            }
            @extend %appearance-none;
            height: 78px;
            border: 1px solid $superLightBlack;
            border-radius: 10px;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            background: transparent;
            @include transitionEase(#{background});
            cursor: pointer;
            &__title {
                @include get-font($f12, $fw-b, $lightBlack, $lh);
                letter-spacing: 0.6px;
                text-transform: uppercase;
            }
            &__helper {
                @include get-font($f16, $fw-sb, $black, $lh);
                @media only screen and (max-width: $onlyMobile) {
                    font-size: 14px; 
                } 
                @media only screen and (max-width:360px) {
                    font-size: 13px; 
                } 
            }
            &__details {
                &--right {
                    margin-right: 16px;
                    text-align: right;
                    @media only screen and (max-width: $onlyMobile) {
                        margin-right: 8px;
                    }  
                }
                &--left {
                    margin-left: 16px;
                    text-align: left;
                    @media only screen and (max-width: $onlyMobile) {
                        margin-left: 8px;
                    }
                }
            }
            &:hover, &:active, &:focus {
                background: $grayBackground;
            }
            &__icon {
                width: 24px;
                &__img_right{
                    transform: rotate(90deg);
                }
                &__img_left {
                    transform: rotate(270deg);
                }
                &--left {
                    transform: rotate(180deg);
                }
            }
            &--primary {
                background: $blue;
                .collaboration-form__submit__btn-big__title {
                    color: rgba($white, 0.6);
                }
                .collaboration-form__submit__btn-big__helper {
                    color: $white;
                }
                &:hover, &:active, &:focus {
                    background: $darkBlue;
                }
            }
            @media only screen and (max-width: $onlyMobile) {
                &.mobile-collaboration-submit{
                    padding: 12px;
                    justify-content: space-between;
                }
            }

        }
    }
    &__product-review {
        &__header {
            &__img{
                transform: rotate(270deg)
            }
            @include get-font($f18, $fw-sb, $black, $lh);
            margin: 0;
            margin-bottom: 32px;
            &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 32px;
                .collaboration-form__product-review__header {
                    margin-bottom: 0;
                }
            }
            &__skip {
                @extend %appearance-none;
                background: transparent;
                border: 0;
                cursor: pointer;
                @include get-font($f12, $fw-b, $black);
                display: flex;
                align-items: center;
                > img {
                    margin-left: 8px;
                    width: 12px;
                    height: auto;
                }
            }
        }
        .see-all-container__search-input {
            width: 100%;
            height: 48px;
        }
        .end-container {
            max-width: 100%;
        }
        &__questionnaire {
            &__list {
                margin: 0 auto 32px;
                padding: 0;
                list-style: none;
                width: 100%;
                &__item {
                    &__container {
                        margin-bottom: 12px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    position: relative;
                    background: $white;
                    border-radius: 10px;
                    display: block;
                    padding: 16px 24px;
                    box-sizing: border-box;
                    @extend %border-box-shadow;
                    cursor: pointer;
                    &:hover {
                        background: $grayBackground;
                        border-color: transparent;
                    }
                    &--checked {
                        background: $blue;
                        border-color: transparent;
                        &:hover {
                            background: $blue;
                        }
                        .collaboration-form__product-review__questionnaire__title {
                            color: $white;
                        }
                    }
                }
            }
            &__title {
                @include get-font($f16, $fw-m, $black, $lh);
            }
            &__input {
                position: absolute;
                opacity: 0;
            }
            &__check {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: auto;
                margin-right: 24px;
            }
        }
    }
    &__products {
        &__container {
            position: relative;
        }
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($white, 0.6);
            z-index: 1;
        }
        &__count-list {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            &__item {
                display: inline-block;
                width: 56px;
                height: 45px;
                background: $white;
                @include get-font($f14, $fw-sb, $black, $lh);
                line-height: 45px;
                text-align: center;
                border: 0.5px solid $superLightBlack;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 4px;
                justify-content: center;
                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
                &--active {
                    background: $blue;
                    color: $white;
                }
                &--disabled {
                    cursor: not-allowed;
                }
            }
        }
        &__form {
            padding: 32px 24px;
            margin-bottom: 24px;
            position: relative;
            overflow: hidden;
            &--disabled {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $superLightBlack;
                border-radius: 10px;
            }
            &__group {
                margin-bottom: 48px;
                position: relative;
                &:last-child {
                    margin-bottom: 0;
                }
                &--multi {
                    display: flex;
                    > div {
                        flex: 1;
                        margin-right: 24px;
                        @media only screen and (max-width: $onlyMobile) {
                            margin-right: 0;;
                        }
                    }
                    @media only screen and (max-width: $onlyMobile) {
                        flex-direction: column;
                        gap: 20px;
                    }
                }

            }
            &__text-input {
                @extend %appearance-none;
                @include get-font($f16, $fw-r, $black, $lh);
                height: 48px;
                background: $grayBackground;
                border-radius: 10px;
                width: 100%;
                border: 0;
                padding: 0 16px;
                box-sizing: border-box;
                &:focus, &:active {
                    border: 1px solid $mediumBlack;
                }
                &.disabled {
                    cursor: not-allowed;
                    &:focus, &:active {
                        border: none;
                    }
                }
            }
        }
        &__color {
            flex: 1;
            height: 48px;
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
            position: relative;
            border-radius: 10px;
            line-height: 40px;
            text-align: center;
            input {
                position: absolute;
                opacity: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &__check {
                width: 20px;
                vertical-align: middle;
            }
            &__container {
                flex: 1;
                flex-wrap: wrap;
                flex-grow: 1;
                display: flex;
                width: 100%;
            }
            @media only screen and (max-width: $onlyMobile) {
                display: flex;
                height: 40px;
                justify-content: center;
                align-items: center;
            }   
        }
    }
    &__checkbox {
        display: block;
        cursor: pointer;
        &:hover {
            background-color: #EDEDED;
        }
        &__container {
            padding: 32px 24px;
            @media only screen and (max-width: $onlyMobile) {
                padding: 12px;
            }
        }
        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            // &:hover {
            //     background-color: #EDEDED;
            // }
            &__item {
                flex-grow: 1;
                width: 29%;
                padding: 12px 8px;
                // padding-bottom: 32px;
                &:hover {
                    background-color: #EDEDED;
                    border-radius: 4px;
                }
            }
        }
        &__input {
            margin-right: 8px;
        }
        &__title {
            display: inline;
            @include get-font($f16, $fw-r, $black, $lh);
            &--checked {
                font-weight: $fw-m;
            }
        }
    }
    &__default-container {
        @extend %border-box-shadow;
        border-radius: 10px;
        background: $white;
        box-sizing: border-box;
    }
    &__tasting {
        display: flex;
        flex: 1;
        align-items: flex-start;
        justify-content: flex-start;
        &--with-margin {
            margin-bottom: 32px;
        }
        &__title {
            @include get-font($f14, $fw-m, $black, $lh);
            margin-bottom: 4px;
        }
        &__helper {
            @include get-font($f14, $fw-r, $lightBlack, $lh);
        }
        &__description {
            margin-right: 12px;
            flex: 1;
        }
        &--disabled {
            cursor: not-allowed;
        }
        &__checkbox {
            &--disabled {
                opacity: 0.65;
            }
            margin-right: 16px;
            margin-top: 3px;
        }
        &__input {
            margin-right: 12px;
            &--document {
                left: 0;
                opacity: 0;
                position: absolute;
            }
        }
        &__container {
            padding: 32px 24px;
        }
        &__group {
            margin-bottom: 24px;
            display: flex;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__btn {
            @extend %appearance-none;
            @include form-input-border-shadow();
            height: 48px;
            background: $white;
            @include get-font($f16, $fw-sb, $black, $lh);
            max-width: 147px;
            padding: 12px 24px;
            box-sizing: border-box;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 12px;
            @media only screen and (max-width:819px) {
                width: 100%;
              }
        }
    }
    &__date-picker {
        &__container {
            display: inline-flex;
            height: 48px;
            background: $white;
            @extend %border-box-shadow;
            border-radius: 10px;
            padding: 0 16px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                margin-right: 12px;
            }
        }
        @include get-font($f16, $fw-sb, $black);
        border: 0;
        outline: 0;
        width: 95px;
    }
    // hack to change the tiny mce toolbar
    .tox-editor-container { // changing the direction of toolbar to bottom
        flex-direction: column-reverse !important;
    }
    .tox-tinymce { // tiny mce container
        @include form-input-border-shadow();
        border-radius: 10px;
    
    }
    .tox-toolbar__primary { // changing toolbar background
        background: $white !important;
    }
    .tox-toolbar__group { // adding left and right padding to toolbar
        padding: 0 24px !important;
    }
    .tox-editor-header {
        border-top: 1px solid $grayBackground;
    }
    .tox-toolbar-overlord { // adding padding top and bottom
        padding: 4px 0;
        box-sizing: border-box;
    }
    .tox-tbtn { // bui buttons
        margin-right: 12px !important;
        svg {
            fill: $lightBlack !important;
        }
        &--enabled {
            background: $grayBackground !important;
            svg {
                fill: $black !important;
            }
        }
    }
    .tox-edit-area__iframe { // content
        padding-left: 0px !important;
        padding-right: 12px !important;
        @media screen and (max-width: $onlyMobile) {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

}
@media screen and (max-width: $onlyMobile) {
    .collaboration-form__date-picker__container{
        width: 100%;
        padding: 0px;
        justify-content: flex-start;
    }
    .collaboration-form__date-picker__container img{
        margin: 0px 16px;
    }
    .collaboration-form{
        &__submit{
            &--multi{
                .default-modal{
                    &__backdrop{
                        .default-modal__container{
                            width: 100%;
                            top: 15%;
                        }
                    } 
                }
            }
        }
        &__outlets__outlet__input{
            max-width: 80%;
        }
        &__image-container--helper{
                .loader__container{
                    flex-wrap: wrap;
                    gap: 0px;
                    align-items: center;
                    justify-content: center;
                }
        }
        &__image-container{
            &__remove{
                height: 20px;
                width: 20px;
                border-radius: 20px;
                &__icon{
                    width: auto;
                    height: 20px;
                }
            }
        }
    }
}
.collaboration-select-highlight{
    color: #171717 !important;
}