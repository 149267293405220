.tasting-form {
  margin: 0 auto;
  padding: 100px 0;
  max-width: 640px;
  @media screen and (max-width: $onlyMobile) {
    margin-top: 56px;
    margin-bottom: 32px;
    width: calc(100%);
    padding: 20px 16px 32px 16px;
    box-sizing: border-box;
  }
  .review-header {
    display: flex;
    margin-bottom: 24px;
    &--image {
      width: 64px;
      height: 42px;
      object-fit: cover;
      border-radius: 7px;
      margin-right: 8px;
    }
    &__text-container {
      &--primary {
        @include get-font($f14, $fw-r, $lightBlack, $lh);
        margin-bottom: 2px;
      }
      &--secondary {
        margin: 0px;
        @include get-font($f14, $fw-sb, $black, $lh);
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: flex-start;
    &__name {
      @include get-font($f22, $fw-b, $onboardingBlack, $lh);
      text-transform: capitalize;
    }
    &__button {
      background: $tastingGreen;
      border-radius: 12px;
      padding: 5px 10px;
      border: 0;
      outline: 0;
      cursor: pointer;
      &__text {
        @include get-font($f12, $fw-sb, $onboardingBlack, $lh);
      }
      &__icon {
        margin-left: 4px;
      }
    }
  }
  &__header-loader {
    position: fixed;
    width: 100%;
    top: 65px;
    @media only screen and (max-width: $onlyMobile) {
      top: 56px;
    }
    height: 6px;
    z-index: 11;
    &__inner {
      background: $yellow;
      height: 6px;
    }
  }
  &__container {
    margin-bottom: 16px;
    &__error {
      margin-top: 8px;
    }
  }
  &__card {
    background: $white;
    border: solid 0.5px $superLightBlack;
    padding: 24px 16px;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 $tastingCardShadow;
    white-space: pre-wrap;
    &__question {
      &__info {
        position: relative;
        margin-left: 8px;
        > img {
          cursor: pointer;
          vertical-align: text-top;
        }
      }
    }
    &--error {
      border: solid 1.5px $removeRed;
      box-shadow: none;
    }
    &--nested {
      padding: 0;
      box-shadow: none;
      border: 0;
      .tasting-form__card__question-heading {
        font-size: 14px;
      }
    }
    &__title {
      @include get-font($f12, $fw-sb, $tastingHeadBlack, $lh);
      text-transform: uppercase;
    }
    &__question-heading {
      margin: 8px 0 0 0;
      @include get-font($f16, $fw-sb, $black, $lh);
      sup{
        color: $red;
      }
      .title-text{
        word-break: break-word;
      }
    }
    &__question-sub-heading {
      margin: 8px 0 0 0;
      @include get-font($f14, $fw-r, $lightBlack, $lh);
    }
    &__nested-questions {
      margin-top: 24px;
    }
    &__rankquestion {
      &__list {
        margin-top: 16px;
      }
      &__option {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &__title {
          min-width: 60px;
          @include get-font($f14, $fw-r, $black, $lh);
        }
        &__dropdown {
          width: 100%;
          &__control {
            border-radius: 10px !important;
            min-height: 50px !important;
            padding: 2px 0;
            border: 0.5px solid rgba(0, 0, 0, 0.1);

            &--is-focused{
              border-color: rgb(204, 204, 204) !important;
              box-shadow: none !important;
            }
          }

          &__value-container {
            height: 40px !important;
          }

          &__indicator {
            color: rgb(102, 102, 102) !important;
            &-separator{
              background-color: transparent !important;
            }
          }

          &__option {
            display: flex;
            padding: 6px 10px;
            &:hover{
              background-color: $backgroundGray;
            }

            &__radiobutton {
              margin-right: 8px;
              margin-left: 6px;
              width: 20px;
            }
            &__textdiv {
              display: flex;
              flex-direction: column;

              &__title {
                &__norank {
                  @include get-font($f14, $fw-r, $black, $lh);
                }
                @include get-font($f14, $fw-r, $black, $lh);
              }
              &__rank {
                @include get-font($f12, $fw-sb, #EFB920, $lh);
              }
            }

            &:hover {
              cursor: pointer;
            }
          }
          &__menu{
            z-index: 13 !important;
            &-list{
              max-height: 300px !important;
              @include getDefaultScrollbar();
            }
          }
        }
      }
    }
    &__rangequestion {
      &__list {
        margin-top: 16px;
      }
      &__option {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &__title {
          min-width: 70px;
          @include get-font($f16, $fw-r, $black, $lh);
        }
        &__dropdown {
          width: 100%;
          &__control {
            border-radius: 10px !important;
            min-height: 50px !important;
            padding: 2px 0;
            border: 0.5px solid rgba(0, 0, 0, 0.1);

            &--is-focused{
              border-color: rgb(204, 204, 204) !important;
              box-shadow: none !important;
            }

            .selected-value{
              img{
                width:20px;
              }
              display: flex;
              align-items: center;
              gap: 10px;
              @include get-font($f16, $fw-r, $black, $lh);
              b{
                font-weight: 500;
              }
            }
          }

          &__value-container {
            height: 40px !important;
          }
          &__indicator {
            color: rgb(102, 102, 102) !important;
          }

          &__option {
            display: flex;
            padding: 10px 10px;
            &:hover{
              background-color: $backgroundGray;
            }
            &:not(:last-child){
              border-bottom: 1px solid $imageWrapperBackgroundGray;
            }

            &__radiobutton {
              margin-right: 8px;
              margin-left: 6px;
              width: 20px;
            }
            &__textdiv {
                @include get-font($f14, $fw-r, $black, $lh);
                b{
                  font-weight: 500;
                }
            }

            &:hover {
              cursor: pointer;
            }
          }
          &__menu{
            box-shadow: none !important;
            &-list{
              @include getDefaultScrollbar();
              scrollbar-color: #ededed #ffffff;
            }
          }
        }
      }
    }
    &__clearselection {
      display: flex;
      margin-top: 24px;
      &__text {
        margin-left: auto;
        @include get-font($f14, $fw-m, $lightestGray, $lh);
      }
    }
  }

  &__footer-container {
    position: fixed;
    width: 100%;
    background: white;
    bottom: 0;
    z-index: 23;
    padding: 14px 0;
    box-shadow: 0 1px 14px 0 rgba(127, 3, 3, 0.1);
   
    &__footer {
      max-width: 640px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: $onlyMobile) {
        padding: 0 16px;
      }
      &__next {
        padding: 6px 12px;
        background: $yellow;
        border-radius: 7px;
        border: none;
        outline: none;
        cursor: pointer;
        @extend %appearance-none;
        display: flex;
        align-items: center;
        @include transitionEase(#{background});
        &:hover {
          background: $darkestYellow;
        }
        &--disabled {
          background: $lightYellow;
          cursor: not-allowed;
          &:hover {
            background: $lightYellow;
          }
        }
        &__text {
          @include get-font($f16, $fw-sb, $black, $lh);
        }
        &__image {
          margin-left: 6px;
        }
      }
      &__previous {
        display: flex;
        align-items: center;
        background: $grayBackground;
        border-radius: 7px;
        border: 0;
        padding: 10px 14px;
        cursor: pointer;
        @extend %appearance-none;
        &--disabled {
          opacity: 0.65;
          cursor: not-allowed;
        }
      }
    }

    &.preview{
      .title{
        @include get-font($f14,$fw-r,$orange);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        text-align: center;
        @media screen and (max-width: $onlyMobile) {
          font-size: 12px;
        }
      }
      .tasting-form__footer-container__footer{
        > p {
          min-width: 36px;
        }
        &__next--disabled{
          background: #f2f2f2;
          opacity: .4;
        }
      }
    }
  }
  &__instructions {
    background: $white;
    padding: 24px;
    box-sizing: border-box;
    @include form-input-border-shadow();
    border-radius: 10px;
    .react-player__video{
      margin-top: 0px;
    } 
    .slick-slider{
      .slick-dots{
        bottom: 32px;
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
        li{
          button{
            &::before{
              content: none;
            }
            &::before{
              content: "";
              opacity: 1;
              position: absolute;
              top: 0;
              left: 0;
              height: 4px;
              width: 12px;
              border-radius: 100px;
              padding: 0;
              background-color: $copiedBackground;
              border: 0.5px solid #747474;
            }
          }
          &.slick-active{
            button{
              &::before{
                background-color: $white;
              }
            }
          }
        }
      }
      .suggestions__arrow{
        top: 50%;
        translate: 0,-50%;
        &--prev{
          margin-left: 16px;
        }
        &--next{
          margin-right: 16px;
        }
      }
      .slick-list{
        border-radius: 8px;
      }
    }
    .collaboration__view__details__video-container {
      margin-bottom: 24px;
    }
    .review-page__suggestions__arrow {
      top: 50%;
      transform: translateY(-50%);
    }
    &__title {
      @include get-font($f22, $fw-b, $black, $lh);
      margin-bottom: 8px;
      margin-top: 0;
    }
    &__sub-title {
      @include get-font($f16, $fw-r, $black, $lh);
    }
    white-space: pre-wrap;
  }
  &__select {
    &__options {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      &--list {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 8px;
      }
    }
    &__image-option {
      width: 143px;
      height: 130px;
      border-radius: 10px;
      position: relative;
      border: 1px solid $superLightBlack;
      background: $white;
      display: block;
      @include transitionEase(#{border-color, background});
      margin-right: 6px;
      margin-bottom: 16px;
      cursor: pointer;
      &:hover {
        .tasting-form__select__image-option__overlay {
          opacity: 1;
        }
      }
      &--checked {
        box-shadow: 0 0 0 1px $yellow;
        border-color: $yellow;
        .tasting-form__select__image-option__checkbox {
          background: transparent;
        }
      }
      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: $tastingOptionYellow;
        z-index: 10;
        @include transitionEase(#{opacity});
        opacity: 0;
        &--checked {
          background: $lightestYellow;
          opacity: 1;
        }
      }
      &__checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 20px;
        height: 20px;
        z-index: 10;
        border-radius: 2px;
      }
      &__img {
        object-fit: cover;
        width: 143px;
        height: 130px;
        max-width: 100%;
        border-radius: 10px;
        object-position: top;
        &__overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $lighterBlack;
          border-radius: 10px;
        }
      }
      &__title {
        position: absolute;
        width: 100%;
        display: block;
        bottom: 0;
        background: $white;
        @include get-font($f14, $fw-m, $black, $lh);
        padding: 6px;
        box-sizing: border-box;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
      }
      &__intensity {
        position: absolute;
        top: 6px;
        right: 6px;
        @include get-font($f14, $fw-sb, $black);
        background: rgba($white, 0.9);
        border-radius: 4px;
        z-index: 11;
        padding: 3px;
        box-sizing: border-box;
        min-width: 24px;
        height: 24px;
        > img {
          display: inline-block;
          margin-right: 2px;
        }
      }
    }
    &__option {
      background: $white;
      padding: 8px 12px;
      border: solid 0.5px $superLightBlack;
      border-radius: 20px;
      @include get-font($f14, $fw-r, $mediumBlack, $lh);
      display: block;
      display: flex;
      align-items: center;
      margin: 0 13px 13px 0;
      box-sizing: border-box;
      @include transitionEase(#{background});
      cursor: pointer;
      &__intensity {
        padding-left: 6px;
        margin-left: 6px;
        border-left: 1px solid $yellow;
        white-space: nowrap;
        > img {
          display: inline-block;
          margin-right: 2px;
        }
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: $tastingOptionYellow;
        }
      }
      &__image {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      &--checked {
        display: flex;
        font-weight: $fw-sb;
        color: $onboardingBlack;
        background-color: $tastingOptionYellow;
        border-color: $tastingYellow;
        box-shadow: 0 0 0 1px $tastingYellow;
      }
    }
    &__option-container-block {
      position: relative;
    }
  }
  &__overall-preference {
    &__container {
      display: flex;
      margin-top: 60px;
      &--slider {
        margin-top: 32px;
        .suggestions__arrow--prev {
          margin-left: unset !important;
          margin-right: 12px;
        }
        .suggestions__arrow--next {
          margin-right: unset !important;
          margin-left: 12px;
        }
        .suggestions__arrow--disabled {
          opacity: 0.5 !important;
          display: block !important;
        }
      }
    }
    &__question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__rating {
        @include get-font($f14, $fw-sb, $lightBlack, $lh);
      }
    }
    &__block {
      width: 60px;
      height: 38px;
      border-radius: 6px;
      background-color: $lightGray;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      position: relative;
      @include get-font($f14, $fw-m, $tastingHeadBlack, $lh);
      cursor: pointer;
      z-index: 10;
      @media screen and (max-width: $onlyMobile) {
        width: 40px;
      }
      &--selected {
        color: $white;
        cursor: pointer;
        position: relative;
        @media screen and (max-width: $onlyMobile) {
          width: 40px;
        }
      }
      &__arrow {
        content: ' ';
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background: inherit;
        position: absolute;
        left: 28%;
        top: 0;
        margin-top: -28px;
        transform: translateX(-50%) rotate(45deg);
        border-left: 1px solid $superLightBlack;
        border-top: 1px solid $superLightBlack;
        z-index: -1;
        @media screen and (max-width: $onlyMobile) {
          width: 20px;
          height: 20px;
          margin-top: -36px;
        }
      }
      &__description {
        @include get-font($f12, $fw-r, $white, $lh);
        background: inherit;
        color: $white;
        position: absolute;
        top: -40px;
        min-width: 80px;
        max-width: fit-content; // width removed for multi lang support
        border-radius: 7px;
        padding: 5px 8px;
        letter-spacing: 0.09px;
        display: none;
        white-space: nowrap;
        text-align: center;
        z-index: 1;
        &--selected {
          left: -36px;
          display: block;
          &--left {
            left: -5px;
          }
          &--right {
            left: unset;
            right: 0; // adjusted for multi lang support
          }
          &--center {
            left: -25px;
          }
          @media screen and (max-width: $onlyMobile) {
            top: -50px;
          }
        }
      }
    }
    &__slider {
      &__block {
        width: 48px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grayBackground;
        border-radius: 10px;
        cursor: pointer;
        margin: 0 4px;
      }
      &__arrow {
        position: relative !important;
        width: 32px !important;
        height: 32px !important;
        flex-shrink: 0;
        transform: unset !important;
        top: unset !important;
        margin-top: 3px;
        background: $superLightBlack !important;
        border: 0 !important;
      }
    }
  }
  &__selfie {
    &__image-container {
      text-align: center;
      &__loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__image {
      width: 256px;
      height: 256px;
      border-radius: 50%;
      margin: 56px 0 36px 0;
      object-fit: cover;
      cursor: pointer;
      @media screen and (max-width: $onlyMobile) {
        margin: 24px 0 32px 0;
      }
      &--placeholder {
        border: 1px solid $superLightBlack;
        box-shadow: 0 2px 4px 0 $superLightBlack;
      }
    }
    &__text-container {
      margin-bottom: 40px;
    }
    &__text {
      display: inline-block;
      border: 0.5px solid $superLightBlack;
      border-radius: 20px;
      padding: 8px 12px;
      cursor: pointer;
    }
    &__heading {
      @include get-font($f22, $fw-b, $black);
      margin: 0 0 4px 0;
      text-align: center;
      sup{
        color: $red;
      }
      .tasting-form__card__question__info {
        height: 26px;
        > img {
          vertical-align: middle !important;
          margin-top: -2px;
        }
      }

      & ~ .helper-message-front__wrapper{
        margin-top: 32px
      }
    }
    &__description {
      @include get-font($f16, $fw-r, $black, $lh);
    }
  }
  &__comments {
    background: $white;
    border: solid 0.5px $superLightBlack;
    padding: 24px 16px;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 $tastingCardShadow;
    &--error {
      background: $white;
      border: solid 1.5px $removeRed;
      padding: 24px 16px;
      border-radius: 10px;
      margin-bottom: 16px;
    }
    &__title {
      margin: 0 0 16px 0;
      @include get-font($f16, $fw-sb, $black, $lh);
    }
    &__textarea {
      width: 100%;
      resize: none;
      border-radius: 10px;
      padding: 12px 16px;
      background: $grayBackground;
      box-sizing: border-box;
      border: none;
      outline: none;
      min-height: 136px;
      margin-top: 16px;
      @include get-font($f16, $fw-sb, $black, $lh);
      &::placeholder {
        @include get-font($f16, $fw-sb, $placeholderBlack, $lh);
      }
    }
  }
  .textual-intensity {
    width: 328px;
    position: absolute;
    background: $white;
    z-index: 20;
    top: 45px;
    border: solid 0.5px $superLightBlack;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    &--with-image {
      top: calc(100% - 10px);
    }
    &--with-position-top {
      top: unset;
      bottom: 100%;
      margin-bottom: 8px;
      &::after {
      top: unset !important;
      bottom: -6px !important;
      transform: translateX(-50%) rotate(223deg) !important;
      }
    }
    &--with-position-center {
      top: 50%;
      left: 100%;
      // z-index: 3;
      transform: translateY(-50%);
      &::after {
        content: ' ';
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        background: #ffffff;
        position: absolute;
        left: -2% !important;
        top: 49% !important;
        transform: translateY(-50%) rotate(316deg) !important;
        border-left: 1px solid $superLightBlack;
        border-top: 1px solid $superLightBlack;
      }
    }
    @media screen and (max-width: $onlyMobile) {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: none;
    }
    &__pill {
      &__container {
        margin: 16px 0;
        text-align: center;
      }
      display: inline-flex;
      height: 36px;
      border-radius: 20px;
      border: solid 0.5px $grayBackground;
      box-sizing: border-box;
      background: $white;
      padding: 10px;
      align-items: center;
      justify-content: center;
      &--selected {
        border: 1.5px solid $tastingYellow;
        background: $tastingOptionYellow;
      }
      &__icon {
        width: 16px;
        margin-right: 6px;
      }
      &__text {
        @include get-font($f14, $fw-m, $black, $lh);
      }
    }
    &::after {
      content: ' ';
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: #ffffff;
      position: absolute;
      left: 50%;
      top: -5px;
      transform: translateX(-50%) rotate(45deg);
      border-left: 1px solid $superLightBlack;
      border-top: 1px solid $superLightBlack;
    }
    &__heading {
      @include get-font($f20, $fw-b, $black, $lh);
      margin: 0 0 4px 0;
    }
    &__sub-heading {
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      margin-bottom: 24px;
    }
    &__scale {
      display: flex;
      flex-direction: column-reverse;
      margin: 0;
      padding: 0;
      &--disabled {
        pointer-events: none !important;
      }
      &__remove {
        cursor: pointer;
        border: 0;
        padding: 0;
        margin: 2px 0 0 0;
        background: $white;
        @extend %appearance-none;
        &__text {
          @include get-font($f14, $fw-r, $removeRed, $lh);
        }
      }
      &__option {
        border: solid 0.5px $superLightBlack;
        display: flex;
        box-shadow: 0 2px 4px 0 $superLightBlack;
        margin-bottom: 10px;
        border-radius: 10px;
        cursor: pointer;
        height: max-content;
        box-sizing: border-box;
        &__secondary {
          padding: 9px 18px;
          background: $grayBackground;
          border-radius: 10px 0 0 10px;
          @include get-font($f14, $fw-sb, $black, $lh);
          justify-content: center;
          align-items: center;
          display: flex;
          height: auto;
          box-sizing: border-box;
        }
        &__primary {
          padding: 12px 16px;
          @include get-font($f14, $fw-sb, $black, $lh);
        }
        &--selected {
          background: $yellow;
          border-color: transparent;
        }
      }
    }
  }
  &__nested-options {
    &__container {
      margin-top: 12px;
    }
    &__input {
      width: 100%;
      border-radius: 5px;
      height: 36px;
      background: $grayBackground;
      display: flex;
      align-items: center;
      padding: 12px 8px;
      box-sizing: border-box;
      cursor: text;
      &__icon {
        width: 12px;
        height: auto;
        margin-right: 8px;
      }
      &__text {
        @include get-font($f16, $fw-r, $lightBlack, $lh);
        letter-spacing: -0.32px;
      }
    }
    &__list {
      margin: 40px 0 0;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      @media only screen and (max-width: $onlyMobile) {
        justify-content: center;
      }
      &__item {
        width: 146px;
        height: 130px;
        border-radius: 10px;
        border: solid 1px $superLightBlack;
        background: $white;
        box-sizing: border-box;
        margin-right: 7px;
        margin-bottom: 16px;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        &:nth-child(4n) {
          margin-right: 0;
        }
        position: relative;
        &__overlay {
          position: absolute;
          background: transparent;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          &--visible {
            background: $lighterBlack;
          }
        }
        &--checked {
          border-color: $yellow;
          box-shadow: 0 0 0 1px $yellow;
        }
        @media only screen and (max-width: $onlyMobile) {
          &:nth-child(4n) {
            margin-right: 7px;
          }
        }
      }
      &__nested {
        @extend %appearance-none;
        border: 0;
        background: transparent;
        padding: 0;
        margin-left: 6px;
        flex-shrink: 0;
        cursor: pointer;
        > img {
          width: 20px;
          height: auto;
          display: block;
        }
      }
      &__title {
        @include get-font($f14, $fw-m, $black, $lh);
        letter-spacing: 0.1px;
        flex: 1;
        &__container {
          position: absolute;
          bottom: 0;
          background: $white;
          width: 100%;
          padding: 6px 8px;
          box-sizing: border-box;
          &--multi {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            text-align: left;
          }
        }
      }
      &__icon {
        width: 146px;
        height: 96px;
        object-fit: cover;
        display: block;
      }
    }
  }

  // preview 
  &.preview{
    padding-top: 150px;
  }
}

.tasting-background {
  background: url('assets/images/tasting/bg_pattern.png') fixed;
  @media screen and (max-width: $onlyMobile) {
    background: url('assets/images/tasting/bg_mobile.png') fixed;
  }
}

.any-other-modal {
  &__container {
    width: 328px;
    @media screen and (max-width: $onlyMobile) {
      width: 100%;
      max-width: 440px;
      min-height: 300px;
    }
  }
  &__header {
    &__title {
      @include get-font($f18, $fw-b, $onboardingBlack, $lh);
    }
  }
  &__input {
    width: 100%;
    max-width: 100%;
    min-height: 150px;
    box-sizing: border-box;
    padding: 12px 16px;
    border: 0.5px solid $superLightBlack;
    border-radius: 10px;
    outline: 0;
    margin-bottom: 96px;
    background-color: $grayBackground;
    @include get-font($f16, $fw-sb, $onboardingBlack, $lh);
    &--with-intensity {
      margin-bottom: 24px !important;
    }
  }
  .textual-intensity {
    position: relative !important;
    border: 0 !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    margin-bottom: 25px;
    &:after {
      display: none;
    }
    &__overlay {
      &--disabled {
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        z-index: 10000;
      }
    }
  }
  &__footer {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-bottom: -24px;
    height: 56px;
    border-top: 1px solid rgba(23, 23, 23, 0.05);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
    box-sizing: border-box;
    &__with-secondary {
      justify-content: space-between !important;
    }
  }
  &__remove {
    border: 0;
    @include get-font($f14, $fw-r, $red, $lh);
    cursor: pointer;
    background: transparent;
  }
  &__submit {
    @extend %appearance-none;
    height: 32px;
    border: 0;
    background: $yellow;
    @include get-font($f14, $fw-b, $black, $lh);
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 7px;
    cursor: pointer;
    line-height: 32px;
    @include transitionEase(#{background});
    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

// helper

.helper-message-front{
  &__wrapper{
    margin-top: 8px;
  }
  &__content{
    &__media{
      border-radius: 10px 10px 10px 10px;
      &__video {
        border-radius: 10px 10px 10px 10px;
        aspect-ratio: 16 / 9;
        object-fit: cover;
      }
      &__image{
        border-radius: 10px 10px 10px 10px;
        overflow: hidden;
        aspect-ratio: 16 / 9;
        object-fit: cover;
      }
      &.yes-text{
        .helper-message-front__content__media__image{
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        .react-player__video{
          video{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
      .react-player__video{
        margin-top: 0px;
      }

      .post-details__video-container--youtube{
        padding-top: 0;
        margin-top: 0;
        border-radius: 10px
      }

      // slider
      .slick-slider{
        .slick-dots{
          bottom: 32px;
          width: fit-content;
          left: 50%;
          transform: translateX(-50%);
          li{
            button{
              &::before{
                content: none;
              }
              &::before{
                content: "";
                opacity: 1;
                position: absolute;
                top: 0;
                left: 0;
                height: 4px;
                width: 12px;
                border-radius: 100px;
                padding: 0;
                background-color: $copiedBackground;
                border: 0.5px solid #747474;
              }
            }
            &.slick-active{
              button{
                &::before{
                  background-color: $white;
                }
              }
            }
          }
        }
        .suggestions__arrow{
          top: 50%;
          translate: 0,-50%;
          &--prev{
            margin-left: 16px;
          }
          &--next{
            margin-right: 16px;
          }
        }
        .slick-list{
          border-radius: 8px;
        }
      }

    }
    &__text{
      margin-top: -5px;
      padding: 8px;
      font-size: 14px;
      @include get-font($f14,$fw-r,$black);
      border-radius: 0 0 8px 8px;
      background-color: $superLightTeal;

      &.no-media{
        border-radius: 8px 8px 8px 8px;
      }
    }
  }
}

 //preview
 .preview{
  &__title{
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;

    &--container{
      background-color: $white;
      padding: 16px 0;

      &__content{
        max-width:$maxWidthContainer ;
        margin: 0 auto;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        box-sizing: border-box;
        &.space{
          justify-content: space-between;
        }
      }
      .share{
        @include get-font($f14,$fw-m,$white);
        border: none;
        outline: none;
        background-color: $blue;
        cursor: pointer;
        border-radius: 8px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        min-height: 40px;
      }
    }
  }
  &-custom{
    min-height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    justify-content: center;
    &__complete{
      text-align: center;
      .title{
        margin: 40px 0 12px 0;
        font-size: $f24;
        font-weight: $fw-sb;
      }
      .subtitle{
        font-size: $f14;
        font-weight: $fw-r;
        margin-top: 0;
      }
    }
  }
}